<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar title="Campañas por comercial" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="CampaniaComercial"
        :tableCode="'CAMPANIACOMERCIAL'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['idCampania']"
        :massiveActions="false"
        :controlsWidth="70"
      >
      <template v-slot:tableLinks>
          <a class="link" href="/campanias/new/">Nueva campaña</a>
      </template>
      <template v-slot:optionsColumn="{item}">
          <f7-link icon-f7="eye" color="green" :href="`/campanias/view/${item.idCampania}/`"></f7-link>
          <f7-link icon-f7="square_pencil" color="blue" :href="`/campanias/edit/${item.idCampania}/`"></f7-link>
          <!-- <f7-link icon-f7="trash" color="red" @click="$refs.rlist.deleteItem(item.id)"></f7-link> -->
      </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import CampaniaComercial from "./../../js/db/CampaniaComercial.js";
import Utils from "./../../js/Utils.js";

export default {
  components: {
    rDynamicList
  },
  data() {
    return {
      CampaniaComercial,
      Utils
    };
  },
  computed: {},
  methods: {

   },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>