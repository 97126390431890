<template>
  <div v-if="caracteristica.tipo == 'GROUP' && editable">
    <f7-block-title>
      <f7-button
        v-if="!form.readonly"
        small
        icon-f7="trash"
        v-tooltip="'Eliminar'"
        @click="onClickEliminar"
        class="float-right"
      ></f7-button>
      <f7-button
        class="float-right"
        small
        v-if="!form.readonly"
        icon-f7="eye_fill"
        @click="$refs.popoMostrar.open($event.target)"
        v-tooltip="'Mostrar Cuando'"
        :color="
          caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre
            ? 'green'
            : 'gray'
        "
      ></f7-button>
      <f7-popover ref="popoMostrar">
        <f7-list>
          <r-autocomplete
            label="Característica"
            :form="form"
            :name="name + '.mostrarCuando.nombre'"
            labelProp="nombre"
            idProp="nombre"
            :dbItems="getCaracteristicas(form.formData.caracteristicas)"
          >
          </r-autocomplete>
          <r-input
            floating-label
            :label="'Operación'"
            :form="form"
            type="select"
            :name="name + '.mostrarCuando.operacion'"
          >
            <option value="IGUAL">==</option>
            <option value="DIFERENTE">!=</option>
            <option value="MAYOR">&gt;</option>
            <option value="MENOR">&lt;</option>
          </r-input>
          <r-input
            floating-label
            :label="'Valor'"
            :form="form"
            :name="name + '.mostrarCuando.valor'"
          >
          </r-input>
        </f7-list>
      </f7-popover>

      <div>
        <span
          v-if="!form.readonly"
          class="column-drag-handle margin-right cursor-move"
          v-tooltip="$t('common.move')"
          >&uarr;&darr;</span
        >
        <input
          placeholder="Nombre del grupo"
          :readonly="form.readonly"
          class="red-placeholder"
          :value="caracteristica.label"
          @input="caracteristica.label = $event.target.value"
          @change="
            caracteristica.nombre = $event.target.value
              .toLowerCase()
              .replace(/\s/g, '_')
          "
        />
      </div>
    </f7-block-title>
    <f7-list>
      <ul>
        <Container
          group-name="caracteristicas"
          @drop="onDrop(caracteristica.hijas, $event)"
          :get-child-payload="
            getChildPayloadCaracteristicas(caracteristica.hijas)
          "
          :should-accept-drop="
            (src, payload) =>
              src.groupName == 'grupo-caracteristicas' ||
              src.groupName == 'caracteristicas'
          "
          lock-axis="y"
          drag-handle-selector=".column-drag-handle"
        >
          <Draggable
            v-for="(chija, ich) in caracteristica.hijas"
            :key="'chija-' + '-' + ich + '-' + caracteristica.nombre"
          >
            <caracteristica
              :onDrop="onDrop"
              :tipo="chija.tipo"
              :caracteristica="chija"
              :form="form"
              :getChildPayloadCaracteristicas="getChildPayloadCaracteristicas"
              :label="
                chija.tipo == 'NOTA' ? 'Nota para el inspector' : chija.label
              "
              :name="name + '.hijas.' + ich"
              :placeholder="
                chija.tipo == 'NOTA'
                  ? 'Escriba nota que aparecerá al inspector'
                  : 'Valor por defecto'
              "
              :editable="editable"
              :allCaracteristicas="allCaracteristicas"
              :key="'chija-caract-' + '-' + ich + '-' + caracteristica.nombre"
              @eliminar="caracteristica.hijas.splice(ich, 1)"
              :offline="offline"
            ></caracteristica>
          </Draggable>
        </Container>
      </ul>
    </f7-list>
  </div>
  <div v-else-if="caracteristica.tipo == 'GROUP' && cumpleCaracteristicaCondicion(caracteristica, allCaracteristicas)">
    <div class="titulo-caract"> 
      <f7-block-title>{{ caracteristica.label }}</f7-block-title>

      <div class="botones-copiar" v-if="!form.readonly &&  (!offline || (offline && (!Utils.getProperty('viene.enac') || Utils.getProperty('viene.enac') == 'false')))" v-noroles="['ENAC', 'PRODUCCION']">
        <a class="margin-left" tooltip="Copiar" @click="copiarGrupo(caracteristica)">
          <f7-icon f7="doc_on_doc" title="copiar"></f7-icon>
          copiar
        </a>
        <a class="margin-left" tooltip="Pegar" @click="pegarGrupo(caracteristica)">
          <f7-icon f7="doc_on_clipboard" title="pegar"></f7-icon>
          pegar
        </a>
      </div>
    </div>


    <f7-list>
      <ul>
        <div
          v-for="(chija, ich) in caracteristica.hijas"
          :key="'div-caract-' + name + '.hijas.' + ich"
        >
          <caracteristica
            :key="'caract-' + name + '.hijas.' + ich"
            :tipo="chija.tipo"
            :caracteristica="chija"
            :form="form"
            :label="chija.label"
            :name="name + '.hijas.' + ich"
            :allCaracteristicas="allCaracteristicas"
            @change="$emit('change', chija)"
            :offline="offline"
          ></caracteristica>
        </div>
      </ul>
    </f7-list>
  </div>
  <div
    v-else-if="
      tipo == 'NOTA' &&
      !editable &&
      cumpleCaracteristicaCondicion(caracteristica, allCaracteristicas)
    "
    class="nota-label margin-left margin-top"
  >
    * {{ caracteristica.valor }}
  </div>
  <div
    v-else-if="
      tipo == 'FIRMA' &&
      cumpleCaracteristicaCondicion(caracteristica, allCaracteristicas)
    "
  >
    <span
      v-if="!form.readonly && editable"
      class="column-drag-handle cursor-move margin-left margin-top"
      v-tooltip="$t('common.move')"
      >&uarr;&darr;</span
    >
    <input
      v-if="editable"
      class="item-title item-label red-placeholder margin-horizontal margin-top"
      style="border: none"
      :value="label"
      @input="caracteristica.label = $event.target.value"
      @change="
        caracteristica.nombre = $event.target.value
          .toLowerCase()
          .replace(/\s/g, '_')
      "
      placeholder="Nombre de la característica"
    />
    <div v-else class="item-title item-label margin-horizontal margin-top">
      {{ label }}
    </div>
    <f7-row no-gap class="list list-form no-hairlines">
      <f7-col width="100">
        <ul>
          <div v-if="!editable">
            <f7-button
              v-if="!form.readonly"
              fill
              small
              class="display-flex"
              style="width: 50%"
              icon-f7="signature"
              @click="popupFirmaOpened = true"
              >Firmar</f7-button
            >
            <f7-popup
              ref="popupfirma"
              class="popupfirma"
              v-if="popupFirmaOpened"
              :opened="true"
              @popup:opened="onOpenFirmaPopup"
              @popup:closed="
                popupFirmaOpened = false;
                viewSign = false;
              "
            >
              <f7-page>
                <f7-navbar>
                  <f7-nav-left>
                    <f7-link popup-close>Cerrar</f7-link>
                  </f7-nav-left>
                  <f7-nav-title>
                    {{ caracteristica.label }}
                  </f7-nav-title>
                </f7-navbar>
                <div class="fields">
                  <f7-row no-gap class="list list-form no-hairlines">
                    <f7-col width="100" medium="100">
                      <ul>
                        <r-sign
                          v-if="viewSign"
                          :form="form"
                          :name="name + '.valor'"
                          ref="firma"
                          :viewImage="false"
                        ></r-sign>
                      </ul>
                    </f7-col>
                  </f7-row>
                </div>
                <div class="display-inline-block text-align-center">
                  <f7-button
                    fill
                    color="primary"
                    class="display-inline-block margin-horizontal"
                    @click="guardarFirma"
                    >{{ $t("albaranes.guardar") }}</f7-button
                  >
                </div>
              </f7-page>
            </f7-popup>
            <div class="sign-view" v-if="signImage">
              <img :src="signImage" />
            </div>
          </div>
          <span v-else class="espacio-firma"> Espacio para firma</span>
        </ul>
      </f7-col>
    </f7-row>
    <div
      class="margin-horizontal margin-bottom display-flex flex-end width-100"
      v-if="editable"
    >
      <f7-button
        class="float-right margin-horizontal-half"
        small
        v-if="!form.readonly"
        icon-f7="eye_fill"
        @click="$refs.popoMostrar.open($event.target)"
        v-tooltip="'Mostrar Cuando'"
        :color="
          caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre
            ? 'green'
            : 'gray'
        "
      ></f7-button>
      <f7-popover ref="popoMostrar">
        <f7-list>
          <r-autocomplete
            label="Característica"
            :form="form"
            :name="name + '.mostrarCuando.nombre'"
            labelProp="nombre"
            idProp="nombre"
            :dbItems="getCaracteristicas(form.formData.caracteristicas)"
          >
          </r-autocomplete>
          <r-input
            floating-label
            :label="'Operación'"
            :form="form"
            type="select"
            :name="name + '.mostrarCuando.operacion'"
          >
            <option value="IGUAL">==</option>
            <option value="DIFERENTE">!=</option>
            <option value="MAYOR">&gt;</option>
            <option value="MENOR">&lt;</option>
          </r-input>
          <r-input
            floating-label
            :label="'Valor'"
            :form="form"
            :name="name + '.mostrarCuando.valor'"
          >
          </r-input>
        </f7-list>
      </f7-popover>
      <f7-button
        small
        v-if="!form.readonly"
        icon-f7="trash"
        v-tooltip="'Eliminar'"
        @click="onClickEliminar"
      ></f7-button>
      <f7-button
        small
        icon-f7="ellipsis_vertical"
        v-tooltip="'Propiedades'"
        class="display-flex"
        @click="$refs.popo2.open($event.target)"
      ></f7-button>
      <f7-popover ref="popo2">
        <f7-list>
          <f7-list-input
            label="Nombre único"
            type="text"
            :readonly="form.readonly"
            placeholder="Nombre de la característica"
            class="red-placeholder"
            :value="caracteristica.nombre"
            @input="caracteristica.nombre = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            label="Descripción"
            type="textarea"
            :readonly="form.readonly"
            placeholder="Descripción"
            :value="caracteristica.descripcion"
            @input="caracteristica.descripcion = $event.target.value"
          ></f7-list-input>
          <f7-list-item
            checkbox
            label="Pasar a plantilla"
            title="Pasar a plantilla"
            :readonly="form.readonly"
            placeholder="Pasar a plantilla"
            :checked="!caracteristica.noPasarAPlantilla"
            @change="caracteristica.noPasarAPlantilla = !$event.target.checked"
          ></f7-list-item>
        </f7-list>
      </f7-popover>
    </div>
  </div>
  <div
    v-else-if="
      tipo == 'TRANSFORMADOR' &&
      cumpleCaracteristicaCondicion(caracteristica, allCaracteristicas)
    "
  >
    <span
      v-if="!form.readonly && editable"
      class="column-drag-handle cursor-move margin-left margin-top"
      v-tooltip="$t('common.move')"
      >&uarr;&darr;</span
    >
    <input
      v-if="editable"
      class="item-title item-label red-placeholder margin-horizontal margin-top"
      style="border: none"
      :value="label"
      @input="caracteristica.label = $event.target.value"
      @change="
        caracteristica.nombre = $event.target.value
          .toLowerCase()
          .replace(/\s/g, '_')
      "
      placeholder="Nombre de la característica"
    />
    <div v-else class="item-title item-label margin-horizontal margin-top">
      {{ label }}
    </div>
    <f7-row no-gap class="list list-form no-hairlines">
      <div
        v-if="!editable && !form.readonly"
        class="margin-horizontal margin-bottom display-flex"
      >
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="plus"
          v-tooltip="'Añadir Elemento'"
          fill
          @click="addElemento"
        >
          Elemento</f7-button
        >
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="minus"
          v-tooltip="'Borrar Elemento'"
          fill
          @click="borrarElemento"
          color="red"
        >
          Elemento</f7-button
        >
      </div>
      <f7-col width="100">
        <ul>
          <div ref="htmlText" @input="changeHtmlText">
            <div class="html-text">
              <table
                border="1px"
                style="
                  border-collapse: collapse;
                  width: 99%;
                  margin-top: 5px;
                  margin-bottom: 5px;
                "
              >
                <thead
                  style="height: 0; visibility: collapse"
                  class="workaround-cell-size"
                >
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                  <td style="width: 4.16%"></td>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2" style="border: 1px solid black">Marca:</td>
                    <td colspan="6" style="border: 1px solid black">
                      <div
                        class="td-editable"
                        style="padding-left: 5px"
                        :contenteditable="!form.readonly"
                      ></div>
                    </td>
                    <td colspan="3" style="border: 1px solid black">
                      Nº Serie:
                    </td>
                    <td colspan="5" style="border: 1px solid black">
                      <div
                        class="td-editable"
                        style="padding-left: 5px"
                        :contenteditable="!form.readonly"
                      ></div>
                    </td>
                    <td colspan="4" style="border: 1px solid black">
                      Año de fab:
                    </td>
                    <td colspan="4" style="border: 1px solid black">
                      <div
                        class="td-editable"
                        style="padding-left: 5px"
                        :contenteditable="!form.readonly"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="border: 1px solid black">
                      Potencia(kVA):
                    </td>
                    <td colspan="2" style="border: 1px solid black">
                      <div
                        class="td-editable"
                        style="padding-left: 5px"
                        :contenteditable="!form.readonly"
                      ></div>
                    </td>
                    <td colspan="4" style="border: 1px solid black">
                      Tensión 1º(kV):
                    </td>
                    <td colspan="2" style="border: 1px solid black">
                      <div
                        class="td-editable"
                        style="padding-left: 5px"
                        :contenteditable="!form.readonly"
                      ></div>
                    </td>
                    <td colspan="4" style="border: 1px solid black">
                      Tensión 2º(kV):
                    </td>
                    <td colspan="2" style="border: 1px solid black">
                      <div
                        class="td-editable"
                        style="padding-left: 5px"
                        :contenteditable="!form.readonly"
                      ></div>
                    </td>
                    <td colspan="3" style="border: 1px solid black">
                      Dieléctrico:
                    </td>
                    <td colspan="3" style="border: 1px solid black">
                      <div
                        class="td-editable"
                        style="padding-left: 5px"
                        :contenteditable="!form.readonly"
                      ></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ul>
      </f7-col>
    </f7-row>
    <div
      class="margin-horizontal margin-bottom display-flex flex-end width-100"
      v-if="editable"
    >
      <f7-button
        class="float-right margin-horizontal-half"
        small
        v-if="!form.readonly"
        icon-f7="eye_fill"
        @click="$refs.popoMostrar.open($event.target)"
        v-tooltip="'Mostrar Cuando'"
        :color="
          caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre
            ? 'green'
            : 'gray'
        "
      ></f7-button>
      <f7-popover ref="popoMostrar">
        <f7-list>
          <r-autocomplete
            label="Característica"
            :form="form"
            :name="name + '.mostrarCuando.nombre'"
            labelProp="nombre"
            idProp="nombre"
            :dbItems="getCaracteristicas(form.formData.caracteristicas)"
          >
          </r-autocomplete>
          <r-input
            floating-label
            :label="'Operación'"
            :form="form"
            type="select"
            :name="name + '.mostrarCuando.operacion'"
          >
            <option value="IGUAL">==</option>
            <option value="DIFERENTE">!=</option>
            <option value="MAYOR">&gt;</option>
            <option value="MENOR">&lt;</option>
          </r-input>
          <r-input
            floating-label
            :label="'Valor'"
            :form="form"
            :name="name + '.mostrarCuando.valor'"
          >
          </r-input>
        </f7-list>
      </f7-popover>
      <f7-button
        small
        v-if="!form.readonly"
        icon-f7="trash"
        v-tooltip="'Eliminar'"
        @click="onClickEliminar"
      ></f7-button>
      <f7-button
        small
        icon-f7="ellipsis_vertical"
        v-tooltip="'Propiedades'"
        class="display-flex"
        @click="$refs.popo2.open($event.target)"
      ></f7-button>
      <f7-popover ref="popo2">
        <f7-list>
          <f7-list-input
            label="Nombre único"
            type="text"
            :readonly="form.readonly"
            placeholder="Nombre de la característica"
            class="red-placeholder"
            :value="caracteristica.nombre"
            @input="caracteristica.nombre = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            label="Descripción"
            type="textarea"
            :readonly="form.readonly"
            placeholder="Descripción"
            :value="caracteristica.descripcion"
            @input="caracteristica.descripcion = $event.target.value"
          ></f7-list-input>
          <f7-list-item
            checkbox
            label="Pasar a plantilla"
            title="Pasar a plantilla"
            :readonly="form.readonly"
            placeholder="Pasar a plantilla"
            :checked="!caracteristica.noPasarAPlantilla"
            @change="caracteristica.noPasarAPlantilla = !$event.target.checked"
          ></f7-list-item>
        </f7-list>
      </f7-popover>
    </div>
  </div>
  <div
    v-else-if="
      tipo == 'TABLA' ||
      (tipo == 'TABLA_DINAMICA' &&
        cumpleCaracteristicaCondicion(caracteristica, allCaracteristicas))
    "
  >
    <span
      v-if="!form.readonly && editable"
      class="column-drag-handle cursor-move margin-left margin-top"
      v-tooltip="$t('common.move')"
      >&uarr;&darr;</span
    >
    <input
      v-if="editable"
      class="item-title item-label red-placeholder margin-horizontal margin-top"
      style="border: none"
      :value="label"
      @input="caracteristica.label = $event.target.value"
      @change="
        caracteristica.nombre = $event.target.value
          .toLowerCase()
          .replace(/\s/g, '_')
      "
      placeholder="Nombre de la característica"
    />
    <div v-else class="item-title item-label margin-horizontal margin-top">
      {{ label }}
    </div>
    <div v-if="editable" class="display-flex row">
      <div
        v-if="!form.readonly"
        class="margin-horizontal margin-bottom display-flex"
      >
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="plus"
          v-tooltip="'Añadir columna'"
          fill
          @click="addColumna"
        >
          Columna</f7-button
        >
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="minus"
          v-tooltip="'Borrar Columna'"
          fill
          @click="borrarColumna"
          color="red"
        >
          Columna</f7-button
        >
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="plus"
          v-tooltip="'Añadir fila'"
          fill
          @click="addFila"
        >
          Fila</f7-button
        >
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="minus"
          v-tooltip="'Borrar Fila'"
          fill
          @click="borrarFila"
          color="red"
        >
          Fila</f7-button
        >
        <label>
          Tabla dinámica
          <input
            class="margin-horizontal-half"
            :checked="caracteristica.tipo == 'TABLA_DINAMICA'"
            type="checkbox"
            @change="
              $event.target.checked
                ? (caracteristica.tipo = 'TABLA_DINAMICA')
                : (caracteristica.tipo = 'TABLA')
            "
          />
        </label>
      </div>

      <div
        class="margin-horizontal margin-bottom display-flex flex-end width-100"
      >
        <f7-button
          class="float-right margin-horizontal-half"
          small
          v-if="!form.readonly"
          icon-f7="eye_fill"
          @click="$refs.popoMostrar.open($event.target)"
          v-tooltip="'Mostrar Cuando'"
          :color="
            caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre
              ? 'green'
              : 'gray'
          "
        ></f7-button>
        <f7-popover ref="popoMostrar">
          <f7-list>
            <r-autocomplete
              label="Característica"
              :form="form"
              :name="name + '.mostrarCuando.nombre'"
              labelProp="nombre"
              idProp="nombre"
              :dbItems="getCaracteristicas(form.formData.caracteristicas)"
            >
            </r-autocomplete>
            <r-input
              floating-label
              :label="'Operación'"
              :form="form"
              type="select"
              :name="name + '.mostrarCuando.operacion'"
            >
              <option value="IGUAL">==</option>
              <option value="DIFERENTE">!=</option>
              <option value="MAYOR">&gt;</option>
              <option value="MENOR">&lt;</option>
            </r-input>
            <r-input
              floating-label
              :label="'Valor'"
              :form="form"
              :name="name + '.mostrarCuando.valor'"
            >
            </r-input>
          </f7-list>
        </f7-popover>
        <f7-button
          small
          v-if="!form.readonly"
          icon-f7="trash"
          v-tooltip="'Eliminar'"
          @click="onClickEliminar"
        ></f7-button>
        <f7-button
          small
          icon-f7="ellipsis_vertical"
          v-tooltip="'Propiedades'"
          class="display-flex"
          @click="$refs.popo2.open($event.target)"
        ></f7-button>
        <f7-popover ref="popo2">
          <f7-list>
            <f7-list-input
              label="Nombre único"
              type="text"
              :readonly="form.readonly"
              placeholder="Nombre de la característica"
              class="red-placeholder"
              :value="caracteristica.nombre"
              @input="caracteristica.nombre = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              label="Descripción"
              type="textarea"
              :readonly="form.readonly"
              placeholder="Descripción"
              :value="caracteristica.descripcion"
              @input="caracteristica.descripcion = $event.target.value"
            ></f7-list-input>
            <f7-list-item
              checkbox
              label="Pasar a plantilla"
              title="Pasar a plantilla"
              :readonly="form.readonly"
              placeholder="Pasar a plantilla"
              :checked="!caracteristica.noPasarAPlantilla"
              @change="
                caracteristica.noPasarAPlantilla = !$event.target.checked
              "
            ></f7-list-item>
          </f7-list>
        </f7-popover>
      </div>
    </div>
    <div
      v-else-if="tipo == 'TABLA_DINAMICA' && !form.readonly"
      class="margin display-flex"
    >
      <f7-button
        style="max-width: 100px"
        class="margin-horizontal-half"
        small
        icon-f7="plus"
        v-tooltip="'Añadir fila'"
        fill
        @click="addFila"
      >
        Fila</f7-button
      >
      <f7-button
        style="max-width: 100px"
        class="margin-horizontal-half"
        small
        icon-f7="minus"
        v-tooltip="'Borrar Fila'"
        fill
        @click="borrarFila"
        color="red"
      >
        Fila</f7-button
      >
    </div>
    <vue-table-dynamic
      ref="tabla"
      style="--f7-cols-per-row: 1.11111111"
      class="margin tabla-dinamica"
      @cell-change="onChangeCellTable"
      v-if="tableVisible"
      :params="{
        data: tableData,
        header: editable ? '' : 'row',
        border: true,
        stripe: true,
        edit: { row: form.readonly ? [] : 'all' },
      }"
    >
    </vue-table-dynamic>
  </div>
  <div
    v-else-if="
      tipo == 'TABLA_PERSONALIZADA' &&
      cumpleCaracteristicaCondicion(caracteristica, allCaracteristicas)
    "
  >
    <span
      v-if="!form.readonly && editable"
      class="column-drag-handle cursor-move margin-left margin-top"
      v-tooltip="$t('common.move')"
      >&uarr;&darr;</span
    >
    <input
      v-if="editable"
      class="item-title item-label red-placeholder margin-horizontal margin-top"
      style="border: none"
      :value="label"
      @input="caracteristica.label = $event.target.value"
      @change="
        caracteristica.nombre = $event.target.value
          .toLowerCase()
          .replace(/\s/g, '_')
      "
      placeholder="Nombre de la característica"
    />
    <div v-else class="item-title item-label margin-horizontal margin-top">
      {{ label }}
    </div>
    <div v-if="editable" class="display-flex row">
      <div
        v-if="!form.readonly"
        class="custom-table-buttons margin-horizontal margin-bottom display-flex"
      >
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="minus"
          v-tooltip="'Borrar Columna'"
          fill
          @click="borrarColumnaCustomTabla"
          color="red"
        ></f7-button>
        <span>Columnas</span>
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="plus"
          v-tooltip="'Añadir columna'"
          fill
          @click="addColumnaCustomTabla"
        ></f7-button>
        <hr />
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="minus"
          v-tooltip="'Borrar Fila'"
          fill
          @click="borrarFilaCustomTabla"
          color="red"
        ></f7-button>
        <span>Filas</span>
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="plus"
          v-tooltip="'Añadir fila'"
          fill
          @click="addFilaCustomTabla"
        ></f7-button>
        <hr />
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="minus"
          v-tooltip="'Reducir ancho'"
          fill
          @click="encojerColumnaCustomTabla"
          color="red"
        ></f7-button>
        <span class="span-ancho-columna">Ancho columna</span>
        <f7-button
          style="max-width: 100px"
          class="margin-horizontal-half"
          small
          icon-f7="plus"
          v-tooltip="'Aumentar ancho'"
          fill
          @click="agrandarColumnaCustomTabla"
        ></f7-button>
        <hr />
        <f7-button
          ref="BotonCeldaFondo"
          style="max-width: 100px"
          class="margin-horizontal-half"
          :disabled="!celdaSeleccionada"
          small
          icon-f7="paintbrush"
          v-tooltip="'Celda con fondo'"
          fill
          @click="toggleFondoCelda"
        >
          Fondo</f7-button
        >
        <f7-button
          ref="BotonCeldaReadonly"
          style="max-width: 110px"
          class="margin-horizontal-half"
          :disabled="!celdaSeleccionada"
          small
          :icon-f7="celdaSeleccionadaBloqueada ? 'lock_open': 'lock'"
          v-tooltip="'Celda bloqueada'"
          fill
          @click="toggleReadonlyCelda"
        >
          Bloqueada</f7-button
        >
        <f7-button
          ref="BotonCeldaCombinarDcha"
          style="max-width: 150px"
          class="margin-horizontal-half"
          :disabled="!celdaSeleccionada"
          small
          icon-f7="square"
          v-tooltip="'Combinar con la celda de la derecha'"
          fill
          @click="combinarCeldaDcha"
        >
          Combinar celda derecha</f7-button
        >
        <f7-button
          ref="BotonCeldaCombinarDcha"
          style="max-width: 140px"
          class="margin-horizontal-half"
          :disabled="!celdaSeleccionadaCombinada"
          small
          icon-f7="square_split_2x1"
          v-tooltip="'Separar celdas'"
          fill
          @click="separarCelda"
        >
          Separar celda</f7-button
        >
        <f7-button
          ref="BotonCeldaCombinarDcha"
          style="max-width: 140px"
          class="margin-horizontal-half"
          :class="{'color-green': repetible == 'LINEA'}"
          small
          icon-f7="plus_app"
          v-tooltip="'Fila final repetible'"
          fill
          @click="filasDinamicas"
        >
          Filas dinámicas</f7-button
        >
        <f7-button
          ref="BotonCeldaCombinarDcha"
          style="max-width: 140px"
          class="margin-horizontal-half"
          :class="{'color-green': repetible == 'BLOQUE'}"
          small
          icon-f7="plus_rectangle_on_rectangle"
          v-tooltip="'Bloque repetible'"
          fill
          @click="bloqueRepetible"
          color="darkblue"
        >
          Bloque repetible</f7-button
        >
      </div>
      <div
        class="margin-horizontal margin-bottom display-flex flex-end width-100"
      >
        <f7-button
          class="float-right margin-horizontal-half"
          small
          v-if="!form.readonly"
          icon-f7="floppy_disk"
          @click="guardarCambiosTablaPersonalizada"
          v-tooltip="'Guardar cambios'"
          :disabled="!stickybit"
          :color="
            stickybit
              ? 'blue'
              : 'gray'
          "
        ></f7-button>
        <f7-button
          class="float-right margin-horizontal-half"
          small
          v-if="!form.readonly"
          icon-f7="eye_fill"
          @click="$refs.popoMostrar.open($event.target)"
          v-tooltip="'Mostrar Cuando'"
          :color="
            caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre
              ? 'green'
              : 'gray'
          "
        ></f7-button>
        <f7-popover ref="popoMostrar">
          <f7-list>
            <r-autocomplete
              label="Característica"
              :form="form"
              :name="name + '.mostrarCuando.nombre'"
              labelProp="nombre"
              idProp="nombre"
              :dbItems="getCaracteristicas(form.formData.caracteristicas)"
            >
            </r-autocomplete>
            <r-input
              floating-label
              :label="'Operación'"
              :form="form"
              type="select"
              :name="name + '.mostrarCuando.operacion'"
            >
              <option value="IGUAL">==</option>
              <option value="DIFERENTE">!=</option>
              <option value="MAYOR">&gt;</option>
              <option value="MENOR">&lt;</option>
            </r-input>
            <r-input
              floating-label
              :label="'Valor'"
              :form="form"
              :name="name + '.mostrarCuando.valor'"
            >
            </r-input>
          </f7-list>
        </f7-popover>
        <f7-button
          small
          v-if="!form.readonly"
          icon-f7="trash"
          v-tooltip="'Eliminar'"
          @click="onClickEliminar"
        ></f7-button>
        <f7-button
          small
          icon-f7="ellipsis_vertical"
          v-tooltip="'Propiedades'"
          class="display-flex"
          @click="$refs.popo2.open($event.target)"
        ></f7-button>
        <f7-popover ref="popo2">
          <f7-list>
            <f7-list-input
              label="Nombre único"
              type="text"
              :readonly="form.readonly"
              placeholder="Nombre de la característica"
              class="red-placeholder"
              :value="caracteristica.nombre"
              @input="caracteristica.nombre = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              label="Descripción"
              type="textarea"
              :readonly="form.readonly"
              placeholder="Descripción"
              :value="caracteristica.descripcion"
              @input="caracteristica.descripcion = $event.target.value"
            ></f7-list-input>
            <f7-list-item
              checkbox
              label="Pasar a plantilla"
              title="Pasar a plantilla"
              :readonly="form.readonly"
              placeholder="Pasar a plantilla"
              :checked="!caracteristica.noPasarAPlantilla"
              @change="
                caracteristica.noPasarAPlantilla = !$event.target.checked
              "
            ></f7-list-item>
          </f7-list>
        </f7-popover>
      </div>
    </div>
    <div
      v-if="!editable && !form.readonly && repetible"
      class="margin-horizontal margin-bottom display-flex"
    >
      <f7-button
        style="max-width: 100px"
        class="margin-horizontal-half"
        small
        icon-f7="plus"
        v-tooltip="'Añadir Elemento'"
        fill
        @click="addElementoCustom"
      >
        Elemento</f7-button
      >
      <f7-button
        style="max-width: 100px"
        class="margin-horizontal-half"
        small
        icon-f7="minus"
        v-tooltip="'Borrar Elemento'"
        fill
        @click="borrarElementoCustom"
        color="red"
      >
        Elemento</f7-button
      >
    </div>
    <div ref="htmlTablaPersonalizada" class="margin tabla-personalizada" @focusout="manageFocusout" @focusin="manageFocusin">
      <div
        v-for="(line, i) in tablaPersonalizadaProcesada"
        :key="'linea' + i"
        class="linea"
        :style="estiloLinea[i]"
      >
        <div
          v-for="(cell, j) in line"
          :key="'celda' + i + '-' + j"
          :style="estiloCelda[i][j]"
          :data-row="i"
          :data-col="cell.col"
          class="celda"
          :class="cell.classes"
          :contenteditable="editable || !cell.readonly"
          @click="seleccionarCelda"
          @input="celdaModificada($event, i, j)"
        >
          {{ cell.contenido }}
        </div>
      </div>
    </div>
  </div>
  <r-input
    v-else-if="
      cumpleCaracteristicaCondicion(caracteristica, allCaracteristicas)
    "
    :name="getInputName()"
    :form="form"
    :type="getInputType()"
    :step="getInputType() != 'number' ? undefined : '.01'"
    v-on="$listeners"
    :placeholder="placeholder"
    :readonly="isReadonly()"
    :calendar-params="
      getInputType() != 'datepicker'
        ? undefined
        : {
            closeOnSelect: true,
            routableModals: false,
            dateFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
          }
    "
    :dateString="getInputType() != 'datepicker' ? undefined : true"
    :dbItems="getDbItems()"
    @change="manageChangeNotification($event)"
    :saveAsString="true"
    :allMayus="getInputType() == 'text' ? true : false"
  >
    <slot name="label" slot="label">
      <input
        v-if="editable && tipo != 'NOTA'"
        class="item-title item-label red-placeholder"
        style="border: none"
        :value="label"
        @input="caracteristica.label = $event.target.value"
        @change="
          caracteristica.nombre = $event.target.value
            .toLowerCase()
            .replace(/\s/g, '_')
        "
        placeholder="Nombre de la característica"
      />
      <div
        v-else-if="tipo != 'NOTA'"
        class="item-title item-label"
        :class="{
          'item-label-up item-floating-label': getInputType() == 'checks',
        }"
      >
        {{ label }}
      </div>
    </slot>
    <slot>
      <template v-if="caracteristica.options">
        <option v-if="tipo == 'SIMPLELIST'" value=""></option>
        <option
          v-for="(opcion, ico) in caracteristica.options"
          :key="'caracteristica-' + caracteristica.nombre + '-opcion-' + ico"
          :value="opcion ? opcion.trim() : opcion"
        >
          {{ opcion ? opcion.trim() : opcion }}
        </option>
      </template>
    </slot>
    <slot name="media" slot="media">
      <span
        v-if="editable && !form.readonly"
        class="column-drag-handle cursor-move"
        v-tooltip="$t('common.move')"
        >&uarr;&darr;</span
      >
    </slot>
    <div v-if="editable" slot="content" class="item-after">
      <f7-button
        small
        v-if="!form.readonly"
        icon-f7="eye_fill"
        @click="$refs.popoMostrar.open($event.target)"
        v-tooltip="'Mostrar Cuando'"
        :color="
          caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre
            ? 'green'
            : 'gray'
        "
      ></f7-button>
      <f7-popover ref="popoMostrar">
        <f7-list>
          <r-autocomplete
            label="Característica"
            :form="form"
            :name="name + '.mostrarCuando.nombre'"
            labelProp="nombre"
            idProp="nombre"
            :dbItems="getCaracteristicas(form.formData.caracteristicas)"
          >
          </r-autocomplete>
          <r-input
            floating-label
            :label="'Operación'"
            :form="form"
            type="select"
            :name="name + '.mostrarCuando.operacion'"
          >
            <option value="IGUAL">==</option>
            <option value="DIFERENTE">!=</option>
            <option value="MAYOR">&gt;</option>
            <option value="MENOR">&lt;</option>
          </r-input>
          <r-input
            floating-label
            :label="'Valor'"
            :form="form"
            :name="name + '.mostrarCuando.valor'"
          >
          </r-input>
        </f7-list>
      </f7-popover>
      <template v-if="getInputType() == 'checks' || getInputType() == 'select'">
        <f7-button
          small
          v-if="!form.readonly"
          icon-f7="list_number"
          @click="$refs.popo.open($event.target)"
          v-tooltip="$t('componentes.caracteristicas.opciones')"
        ></f7-button>
        <f7-popover
          ref="popo"
          @popover:open="removeEmptyOptions(caracteristica)"
          @popover:closed="removeEmptyOptions(caracteristica)"
        >
          <f7-row class="cabecera-opciones">
            <f7-button
              icon-f7="plus"
              class="nueva-opcion"
              @click="nuevaOpcion(caracteristica.options)"
            ></f7-button>
          </f7-row>
          <f7-list class="limit-height" ref="optionList">
            <f7-list-input
              v-for="(opcion, ico) in caracteristica.options"
              :key="
                'caracteristica-' + caracteristica.nombre + '-opcion2-' + ico
              "
              type="text"
              placeholder="Nombre de la opción"
              clear-button
              :value="opcion"
              @input="
                !$event.target.value
                  ? caracteristica.options.splice(ico, 1)
                  : $set(caracteristica.options, ico, $event.target.value)
              "
            ></f7-list-input>
          </f7-list>
        </f7-popover>
        <f7-button
          small
          v-if="!form.readonly"
          icon-f7="increase_indent"
          @click="$refs.popoimportar.open($event.target)"
          v-tooltip="$t('componentes.caracteristicas.importarOpciones')"
        ></f7-button>
        <f7-popover
          ref="popoimportar"
          class="text-align-center popover-importar"
          @popover:closed="form.formData.comboAImportar = null"
        >
          <f7-list>
            <r-autocomplete
              :dbAdapter="Combos"
              class="col-100 medium-33"
              floating-label
              :label="$t('componentes.caracteristicas.grupoOpciones')"
              :form="form"
              name="comboAImportar"
              labelProp="nombre"
              idProp="id"
            ></r-autocomplete>
          </f7-list>
          <div class="display-inline-block text-align-center boton-importar">
            <f7-button
              fill
              color="green"
              class="display-inline-block margin-horizontal"
              @click="
                importarCombo(caracteristica, form.formData.comboAImportar)
              "
              >{{ $t("componentes.caracteristicas.importar") }}</f7-button
            >
          </div>
        </f7-popover>
      </template>
      <template v-if="vinculables.length">
        <f7-button
          small
          v-if="!form.readonly"
          icon-f7="link"
          v-tooltip="$t('componentes.caracteristicas.vincularValorInspeccion')"
          :color="caracteristica.vinculado ? 'green' : 'gray'"
          @click="$refs.popovincular.open($event.target)"
        ></f7-button>
        <f7-popover
          ref="popovincular"
          class="text-align-center popover-vincular"
        >
          <f7-list>
            <r-autocomplete
              :dbAdapter="null"
              :label="$t('componentes.caracteristicas.valorVinculado')"
              class="col-100"
              :form="form"
              :name="name + '.vinculado'"
              labelProp="name"
              idProp="content"
              :dbItems="vinculables"
              :viewTooltip="false"
              :key="'comboVinculado-' + caracteristica.nombre"
            ></r-autocomplete>
          </f7-list>
          <div
            class="
              display-inline-block
              text-align-center
              boton-vincular
              margin-bottom
            "
          ></div>
        </f7-popover>
      </template>
      <f7-button
        small
        v-if="!form.readonly"
        icon-f7="trash"
        v-tooltip="'Eliminar'"
        @click="onClickEliminar"
      ></f7-button>
      <f7-button
        small
        icon-f7="ellipsis_vertical"
        v-tooltip="'Propiedades'"
        @click="$refs.popo2.open($event.target)"
      ></f7-button>
      <f7-popover ref="popo2">
        <f7-list>
          <f7-list-input
            label="Nombre único"
            type="text"
            :readonly="form.readonly"
            placeholder="Nombre de la característica"
            class="red-placeholder"
            :value="caracteristica.nombre"
            @input="caracteristica.nombre = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            label="Descripción"
            type="textarea"
            :readonly="form.readonly"
            placeholder="Descripción"
            :value="caracteristica.descripcion"
            @input="caracteristica.descripcion = $event.target.value"
          ></f7-list-input>
          <f7-list-item
            checkbox
            label="Pasar a plantilla"
            title="Pasar a plantilla"
            :readonly="form.readonly"
            placeholder="Pasar a plantilla"
            :checked="!caracteristica.noPasarAPlantilla"
            @change="caracteristica.noPasarAPlantilla = !$event.target.checked"
          ></f7-list-item>
          <f7-list-item
            checkbox
            class="bgcolor-item"
            label="ID del ITEM"
            title="ID del ITEM"
            :readonly="form.readonly"
            placeholder="ID del ITEM"
            :checked="caracteristica.idDelItem"
            @change="$set(caracteristica,'idDelItem' , $event.target.checked)"
          ></f7-list-item>
          <f7-list-input
            v-if="caracteristica.idDelItem"
            label="Orden ID ITEM"
            type="number"
            :readonly="form.readonly"
            placeholder="Orden ID ITEM"
            class="bgcolor-item"
            :value="caracteristica.ordenDelItem"
            @input="caracteristica.ordenDelItem = $event.target.value"
          ></f7-list-input>
          <f7-list-item
            checkbox
            class="bgcolor-item"
            label="Nombre del ITEM"
            title="Nombre del ITEM"
            :readonly="form.readonly"
            placeholder="Nombre del ITEM"
            :checked="caracteristica.nombreDelItem"
            @change="caracteristica.nombreDelItem = $event.target.checked"
          ></f7-list-item>
        </f7-list>
      </f7-popover>
    </div>
  </r-input>
</template>
<style>
.red-placeholder *::placeholder,
.red-placeholder::placeholder {
  color: red !important;
  opacity: 0.5 !important;
}
.popover-inner {
  position: relative;
}
</style>
<style scoped>
.botones-copiar {
  align-items: center;
  display: flex;
}
.titulo-caract {
  display: flex;
}
.espacio-firma {
  border: 1px solid black;
  padding: 15px;
  padding-right: 200px;
}
.block-title > div > input,
.block-title > input {
  background: transparent;
  border: none;
  font-weight: bold;
  width: 100%;
}
.cabecera-opciones {
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  background: white;
  border-radius: 4px 4px 0 0;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: #ddd;
  text-align: center;
}
.nueva-opcion {
  width: 100%;
}
.nota-label {
  font-weight: bold;
  font-size: smaller;
}
.limit-height {
  overflow: auto;
  margin-top: 28px;
  max-height: 408px;
}
.popover-importar {
  min-height: 200px;
}
.boton-importar {
  margin-top: 100px;
}
.popover-vincular {
  min-height: 200px;
  min-width: 400px;
}
.boton-vincular {
  margin-top: 100px;
}
.custom-table-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 5px 0;
}
.custom-table-buttons .button-small {
  min-width: 40px;
}
.custom-table-buttons .span-ancho-columna {
  min-width: 100px;
}
.custom-table-buttons hr {
  width: 1px;
  height: 23px;
}
.tabla-personalizada {
  overflow: auto;
}
.tabla-personalizada .linea .celda.class-seleccionada {
  border: 1px solid red;
  z-index: 10;
  position: relative;
}
.bgcolor-item {
  background-color: #e9e7f3;
}
</style>
<script>
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rSign from "./../../components/rSign.vue";
import VueTableDynamic from "vue-table-dynamic";
import Combos from "./../../js/db/Combos.js";
import Utils from "./../../js/Utils.js";
import { Container, Draggable } from "vue-smooth-dnd";
import { EventBus } from "./../../js/EventBus";
import Inspeccion from "../../js/db/Inspeccion"; // TODO abstraer sacando esto a una propiedad/metodo que se cargue desde fuera

export default {
  name: "caracteristica",
  components: {
    rInput,
    rAutocomplete,
    VueTableDynamic,
    Container,
    Draggable,
    Inspeccion,
    rSign,
  },
  data() {
    return {
      self: this,
      Combos,
      Utils,
      nextFocusDinTable: null,
      intervalHtml: null,
      tableData: [
        ["Columna 1", "Columna 2"],
        ["", ""],
      ],
      celdasTablaPersonalizada: [
        [
          { contenido: "Columna 1", classes: "header", ancho: 100 },
          { contenido: "Columna 2", classes: "header", ancho: 100 },
        ],
        [
          { contenido: "", classes: "", ancho: 100 },
          { contenido: "", classes: "", ancho: 100 },
        ],
      ],
      stickybit: false,
      tableVisible: true,
      focusTimeout: null,
      caracteristicaCargada: false,
      vinculables: [],
      montado: false,
      popupFirmaOpened: false,
      firmado: false,
      viewSign: false,
      celdaSeleccionada: null,
      clasesBotonCeldaFondo: "",
      clasesBotonCeldaBloqueada: "",
      anchoLinea: null,
      tecleandoEnCelda: {},
      repetible: null,
      bloqueBase: null
    };
  },
  computed: {
    signImage: function () {
      var self = this;
      if (!self.montado) {
        return false;
      }
      if (
        self.form &&
        self.form.getValue(self.name) &&
        self.form.getValue(self.name).valor &&
        self.form.getValue(self.name).valor != "data:,"
      ) {
        return self.form.getValue(self.name).valor;
      } else {
        return false;
      }
    },
    estiloLinea: function () {
      const self = this,
        lineas = self.tablaPersonalizadaProcesada.map((linea, i) => {
          let estilosLinea =
            "position:relative; display:block; line-height:23px; min-height:23px;";
          if (self.anchoLinea) {
            estilosLinea = estilosLinea + ` width: ${self.anchoLinea}px;`;
          }
          if (i) {
            estilosLinea = estilosLinea + ` top: -${i}px;`;
          }
          return estilosLinea;
        });
      return lineas;
    },
    estiloCelda: function () {
      const self = this,
        lineas = self.tablaPersonalizadaProcesada.map((linea, i) => {
          return linea.map((celda, j) => {
            let estilosCelda = `box-sizing:content-box; position:absolute; top:0; left:${celda.x}px; display:inline-block; border: 1px solid black;padding: 0 7px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; height:21px;`;
            estilosCelda = estilosCelda + ` width: ${celda.ancho - 16}px;`;
            if (
              celda &&
              celda.classes &&
              celda.classes.indexOf("header") >= 0
            ) {
              estilosCelda =
                estilosCelda +
                ` background: ${
                  celda && celda.classes && celda.classes.indexOf("header") >= 0
                    ? "#d9d9d9"
                    : "none"
                };`;
            }
            return estilosCelda;
          });
        });
      return lineas;
    },
    tablaPersonalizadaProcesada: function () {
      const self = this,
        anchoTotalTabla = 680;

      let celdasClon = JSON.parse(
          JSON.stringify(self.celdasTablaPersonalizada)
        ),
        tablaProcesada = celdasClon.map((linea) => {
          let col = 0,
            nuevaLinea = linea.reduce((arr, actual) => {
              if (
                !arr.length ||
                !arr[arr.length - 1].colspan ||
                arr[arr.length - 1].colspan == 1
              ) {
                if (arr.length) {
                  let celdaAnterior = arr[arr.length - 1];
                  actual.x = celdaAnterior.x + celdaAnterior.ancho - 1;
                } else {
                  actual.x = 0;
                }
                actual.col = col;
                arr.push(actual);
              } else {
                let celdaAnterior = arr[arr.length - 1];
                celdaAnterior.colspan--;
                celdaAnterior.ancho += actual.ancho - 1;
              }
              col++;
              return arr;
            }, []);
          return nuevaLinea;
        }),
        anchoTotal = (!tablaProcesada.length) ? 0 : tablaProcesada[0].reduce((acum, actual) => acum + actual.ancho, 1),
        anchoCeldaPrimera = tablaProcesada[0][0].ancho,
        nuevoAnchoCeldaPrimera = anchoTotalTabla - anchoTotal + anchoCeldaPrimera + tablaProcesada.length,
        movimiento = nuevoAnchoCeldaPrimera - anchoCeldaPrimera + 1;
      tablaProcesada.forEach(linea => {
        linea.forEach((celda, i) => {
          if(!i){
            celda.ancho = nuevoAnchoCeldaPrimera + 1;
          } else {
            celda.x += movimiento;
          }
        });
      });
      return tablaProcesada;
    },
    celdaSeleccionadaBloqueada: function(){
      const self = this;
      if(!self.celdaSeleccionada) return false;
      const celda = self._getCelda(self.celdaSeleccionada);
      return celda.readonly;
    },
    celdaSeleccionadaCombinada: function(){
      const self = this;
      if(!self.celdaSeleccionada) return false;
      const celda = self._getCelda(self.celdaSeleccionada);
      return celda.colspan > 1;
    }
  },
  methods: {
    manageChangeNotification: function (ev) {
      const self = this,
        caracteristicaModificada = self._getContext();

      if (!typeof ev == "Event") return; // Only manage normal change events
      EventBus.$emit("caracteristicaModificada", caracteristicaModificada);
    },
    changeHtmlText() {
      const self = this;
      self.caracteristica.valor = self.$refs.htmlText.innerHTML;
    },
    onClickEliminar() {
      const self = this;
      const app = self.$f7;
      app.dialog.confirm(
        "¿Seguro que desea eliminar la característica seleccionada?",
        () => {
          if (self.intervalHtml) {
            window.clearInterval(self.intervalHtml);
          }
          self.$emit("eliminar");
        }
      );
    },
    addElemento() {
      const self = this;
      var cln = self.$refs.htmlText.firstChild.lastChild.cloneNode(true);
      var listEditables = cln.getElementsByClassName("td-editable");
      for (let index = 0; index < listEditables.length; index++) {
        const element = listEditables.item(index);
        element.innerText = "";
      }
      self.$refs.htmlText.firstChild.appendChild(cln);
    },
    borrarElemento() {
      const self = this;
      self.$refs.htmlText.firstChild.removeChild(
        self.$refs.htmlText.firstChild.lastChild
      );
    },
    onOpenFirmaPopup() {
      const self = this;
      setTimeout(function () {
        self.viewSign = true;
      }, 500);
    },
    guardarFirma() {
      const self = this;
      const app = self.$f7;
      self.$refs.firma.updateValue();
      self.$refs.popupfirma.close();
      self.firmado = true;
    },
    cumpleCaracteristicaCondicion(caracteristica, caracteristicas) {
      const self = this;
      if (self.editable) {
        return true;
      }
      if (caracteristica.mostrarCuando && caracteristica.mostrarCuando.nombre) {
        const allCaracteristicas = self.getCaracteristicas(caracteristicas);
        return self.cumpleCondicion(
          caracteristica.mostrarCuando,
          allCaracteristicas
        );
      }
      return true;
    },
    cumpleCondicion(condicion, caracteristicas) {
      const self = this;
      const caracteristicasCoincidentes = caracteristicas.filter(
        (c) => c.nombre == condicion.nombre
      );
      if (!caracteristicasCoincidentes.length) {
        return false;
      }
      const caracteristicaCoincidente = caracteristicasCoincidentes[0];
      var valorA = caracteristicaCoincidente.valor;
      var valorB = condicion.valor;
      if (caracteristicaCoincidente.tipo == "NUMBER") {
        valorA = parseFloat(valorA);
        valorB = parseFloat(valorB.replace(",", "."));
      }
      if (condicion.operacion == "IGUAL") {
        if (
          typeof valorA == "string" &&
          (caracteristicaCoincidente.options ||
            caracteristicaCoincidente.options.length)
        ) {
          return valorA && valorA.split(",").includes(valorB);
        }
        return valorA == valorB;
      } else if (condicion.operacion == "DIFERENTE") {
        if (
          typeof valorA == "string" &&
          (caracteristicaCoincidente.options ||
            caracteristicaCoincidente.options.length)
        ) {
          return !valorA || !valorA.split(",").includes(valorB);
        }
        return valorA != valorB;
      } else if (condicion.operacion == "MAYOR") {
        return valorA > valorB;
      } else if (condicion.operacion == "MENOR") {
        return valorA < valorB;
      }
    },
    onChangeCellTable() {
      const self = this;
      var $el = this.$$(this.$el);
      var data = self.$refs.tabla.getData().map((row,i) => {
        return i==0 ? row : row.map(cell => self._unescapeHTML(cell).toUpperCase().trim());
      });
      self.tableData = JSON.parse(
        self._unescapeHTML(JSON.stringify(data))
      );
      self.tableVisible = false;
      self.$nextTick((_) => {
        self.tableVisible = true;
        self.$nextTick((_) => {
          if (self.nextFocusDinTable) {
            let celdas = $el[0].querySelectorAll('span[contentEditable=true]');
            if (celdas && celdas.length) {
              let focusable = celdas[self.nextFocusDinTable];
              if (focusable) {
                focusable.focus();
              }
            }
          }
        });
      });
      self.actualizarValorDesdeTabla();
    },
    conClickCellTable(fila, columna, contenido) {
      const self = this;
      self.tableData = JSON.parse(
        self._unescapeHTML(JSON.stringify(self.$refs.tabla.getData()))
      );
      self.tableVisible = false;
      self.$nextTick((_) => (self.tableVisible = true));
      self.actualizarValorDesdeTabla();
    },
    addColumna() {
      const self = this;
      var data = self.tableData;

      let rowNum = data.length;
      let columnNum = data[0].length;

      data[0].push(`Columna ${columnNum + 1}`);
      for (let i = 1; i < rowNum; i++) {
        data[i].push("");
      }

      setTimeout(self.actualizarValorDesdeTabla, 1);
    },
    borrarColumna() {
      const self = this;
      var data = self.tableData;

      let rowNum = data.length;
      let columnNum = data[0].length;

      for (let i = 0; i < rowNum; i++) {
        data[i].pop();
      }
    },
    addFila() {
      const self = this;
      var data = self.tableData;
      var arrPush = [];
      if (data && data[0]) {
        data[0].forEach((element) => {
          arrPush.push("");
        });
      }
      data.push(arrPush);
      setTimeout(self.actualizarValorDesdeTabla, 1);
    },
    borrarFila() {
      const self = this;
      var data = self.tableData;
      data.pop();
      setTimeout(self.actualizarValorDesdeTabla, 1);
    },
    addColumnaCustomTabla() {
      const self = this;
      var data = self.celdasTablaPersonalizada;

      let rowNum = data.length,
        columnNum = data[0].length;

      for (let i = 0; i < rowNum; i++) {
        let linea = data[i],
          ultimaCelda = linea[linea.length - 1],
          nuevaCelda = Object.assign({}, ultimaCelda);
        nuevaCelda.classes = self._removeClass(
          nuevaCelda.classes,
          "seleccionada"
        );
        if (i) {
          nuevaCelda.contenido = "";
        } else {
          nuevaCelda.contenido = `Columna ${columnNum + 1}`;
        }
        data[i].push(nuevaCelda);
      }
      self.actualizarAnchoLinea();
      self._actualizarCustomTabla();
    },
    borrarColumnaCustomTabla() {
      const self = this;
      var data = self.celdasTablaPersonalizada;

      let rowNum = data.length;
      let columnNum = data[0].length;

      for (let i = 0; i < rowNum; i++) {
        data[i].pop();
      }
      self.actualizarAnchoLinea();
      self._actualizarCustomTabla();
    },
    addFilaCustomTabla() {
      const self = this;
      var data = self.celdasTablaPersonalizada;

      let rowNum = data.length,
        columnNum = data[0].length,
        ultimaLinea = data[rowNum - 1],
        arrPush = [];

      for (let i = 0; i < columnNum; i++) {
        var nuevaCelda = Object.assign({}, ultimaLinea[i]);
        nuevaCelda.classes = self._removeClass(
          nuevaCelda.classes,
          "class-seleccionada"
        );
        nuevaCelda.contenido = "";
        arrPush.push(nuevaCelda);
      }
      data.push(arrPush);
      self._actualizarCustomTabla();
    },
    agrandarColumnaCustomTabla() {
      const self = this;
      self._modificarColumnaCustomTabla(20);
      self.actualizarAnchoLinea();
      self._actualizarCustomTabla();
    },
    encojerColumnaCustomTabla() {
      const self = this;
      self._modificarColumnaCustomTabla(-20);
      self.actualizarAnchoLinea();
      self._actualizarCustomTabla();
    },
    _actualizarCustomTabla() {
      const self = this;
      setTimeout(self.actualizarValorDesdeCustomTabla, 1);
    },
    _modificarColumnaCustomTabla(cambio) {
      const self = this,
        celda = self.celdaSeleccionada;
      let data = self.celdasTablaPersonalizada;
      if (!celda) {
        return;
      }
      data.forEach((fila) => {
        fila[celda.col]["ancho"] += cambio;
      });
    },
    borrarFilaCustomTabla() {
      const self = this;
      let data = self.celdasTablaPersonalizada;
      data.pop();
      self._actualizarCustomTabla();
    },
    addElementoCustom() {
      const self = this,
        tipoRepeticion = self.repetible,
        bloqueBase = self.bloqueBase;
      switch(tipoRepeticion){
        case "LINEA":
          let nuevaLinea = Object.assign([],self.celdasTablaPersonalizada[self.celdasTablaPersonalizada.length - 1]);
          nuevaLinea = nuevaLinea.map(celda => Object.assign({}, celda));
          self.celdasTablaPersonalizada.push(nuevaLinea);
          break;
        case "BLOQUE":
          bloqueBase.forEach(nuevaLinea => {
            nuevaLinea = nuevaLinea.map(celda => Object.assign({}, celda));
            self.celdasTablaPersonalizada.push(nuevaLinea);
          })
          break;
      }
      self._actualizarCustomTabla();

    },
    borrarElementoCustom() {
      const self = this,
        tipoRepeticion = self.repetible,
        bloqueBase = self.bloqueBase;
      if(self.celdasTablaPersonalizada.length <= bloqueBase.length){
        return;
      }
      switch(tipoRepeticion){
        case "LINEA":
          self.celdasTablaPersonalizada.pop();
          break;
        case "BLOQUE":
          for(var i=0; i < bloqueBase.length; i++){
            self.celdasTablaPersonalizada.pop();
          }
          break;
      }
      self._actualizarCustomTabla();
    },
    manageFocusout: function() {
      const self = this;
      self.$nextTick(self.guardarCambiosTablaPersonalizada());
    },
    manageFocusin: function() {
      const self = this;
      if(self.focusTimeout){
        clearTimeout(self.focusTimeout);
      }
    },
    guardarCambiosTablaPersonalizada() {
      const self = this,
        tablaModificada = self.$refs.htmlTablaPersonalizada,
        celdas = Array.from(tablaModificada.getElementsByClassName("celda"));
      celdas.forEach(celda => {
        celda.innerHTML = celda.innerHTML.trim();
        let row = celda.getAttribute("data-row"),
          col =  celda.getAttribute("data-col"),
          content = self._unescapeHTML(celda.innerHTML).trim();
        if(!celda.classList.contains('header')){
          content = content.toUpperCase();
        }
        if(!self.celdasTablaPersonalizada || !self.celdasTablaPersonalizada[row] || !self.celdasTablaPersonalizada[row][col]){
          return;
        }
        self.celdasTablaPersonalizada[row][col].contenido = content;
      });
      setTimeout(function(){
        self.stickybit = false;
        self.actualizarValorDesdeCustomTabla()
      }, 100);
    },
    celdaModificada(ev, i, j) {
      const self = this;
      self.stickybit = true;
    },
    actualizarValorDesdeCustomTabla() {
      const self = this,
        contenedorHTML = self.$refs.htmlTablaPersonalizada,
        html = contenedorHTML.innerHTML;
      self.caracteristica.valor = html.replaceAll("class-seleccionada", "");
      self.caracteristica.datosAuxiliares = self._unescapeHTML(
        JSON.stringify(self.exportarDatosAuxiliares())
      );
    },
    seleccionarCelda(ev) {
      const self = this,
        celdas = self.celdasTablaPersonalizada,
        celdaEv = ev.currentTarget;
      if (!celdaEv) {
        return;
      }
      self.celdaSeleccionada = {
        row: celdaEv.getAttribute("data-row"),
        col: celdaEv.getAttribute("data-col"),
      };
      celdas.flat().forEach((celda) => {
        celda.classes = self._removeClass(celda.classes, "class-seleccionada");
      });
      const celda = self._getCelda(self.celdaSeleccionada);
      celda.classes = self._addClass(celda.classes, "class-seleccionada");
    },
    toggleFondoCelda() {
      const self = this,
        celda = self._getCelda(self.celdaSeleccionada);
      if (!celda) {
        return;
      }
      celda.classes = self._toggleClass(celda.classes, "header");
    },
    toggleReadonlyCelda() {
      const self = this,
        celda = self._getCelda(self.celdaSeleccionada);
      if (!celda) {
        return;
      }
      celda.classes = self._toggleClass(celda.classes, "readonly");
      self.$set(celda, "readonly", !celda.readonly);
      self._actualizarCustomTabla();
    },
    combinarCeldaDcha() {
      const self = this,
        celda = self._getCelda(self.celdaSeleccionada);
      self.$set(celda, "colspan", celda.colspan ? celda.colspan + 1 : 2);
      self._actualizarCustomTabla();
    },
    separarCelda() {
      const self = this,
        tabla = self.celdasTablaPersonalizada,
        celda = self._getCelda(self.celdaSeleccionada);
      celda.colspan = 1;
      self._actualizarCustomTabla();
    },
    filasDinamicas() {
      const self = this;
      self._modoRepetible("LINEA");
    },
    bloqueRepetible() {
      const self = this;
      self._modoRepetible("BLOQUE");
    },
    _modoRepetible(modo) {
      const self = this;
      if (self.repetible == modo) {
        self.repetible = null;
      } else {
        self.repetible = modo;
      }
      self._actualizarCustomTabla();
    },
    actualizarAnchoLinea() {
      const self = this;
      let data = self.celdasTablaPersonalizada,
        linea = data.length ? data[0] : {},
        ancho = linea.reduce((a, b) => a + b.ancho + 1 + 14, 1);
      self.anchoLinea = Math.max(ancho, 200);
    },
    actualizarValorDesdeTabla() {
      const self = this;
      self.caracteristica.valor = self._unescapeHTML(
        JSON.stringify(self.$refs.tabla.getData())
      );
    },
    getCaracteristicas(caracteristicas) {
      const self = this;
      if (!caracteristicas || !caracteristicas.length) {
        return [];
      }
      var result = Object.assign([], caracteristicas);
      var hijas = caracteristicas.flatMap((c) => {
        return self.getCaracteristicas(c.hijas);
      });
      return result.concat(hijas);
    },
    getDbItems: function () {
      const self = this;
      if (self.caracteristica.options) {
        var opciones = self.caracteristica.options.map((o) => {
          return { name: o, value: o };
        });
        return opciones;
      }
      return [];
    },
    getInputName: function () {
      const self = this;
      if (self._isAutocalculado()) {
        if (self._isEditable()) {
          return self.name + ".valorFormula";
        }
        return self.name + ".valorResultado";
      }
      return self.name + ".valor";
    },
    isReadonly: function () {
      var self = this;
      return self._isAutocalculado() && !self._isEditable();
    },
    getInputType: function () {
      var self = this;
      switch (self.tipo) {
        case "DATE":
          return "datepicker";
          break;
        case "TEXT":
          return "text";
          break;
        case "AUTOCALCULADO":
          return "text";
          break;
        case "TEXTAREA":
        case "NOTA":
          return "textarea";
          break;
        case "SIMPLELIST":
        case "RADIO":
          return "select";
          break;
        case "MULTILIST":
        case "MULTICHECK":
          return "checks";
          break;
        case "CHECK":
          return "check";
          break;
        case "RADIO":
          return "select";
          break;
        case "NUMBER":
          return "number";
          break;
      }
    },
    setLabel(e) {
      console.log(e);
    },
    nuevaOpcion(opciones) {
      var self = this;
      opciones.push("");
      var lista = self.$refs.optionList.$el;
      setTimeout((_) => {
        lista.scrollTop = lista.scrollHeight;
      }, 100); // Ñapa necesaria porque esto no es buena praxis.
    },
    removeEmptyOptions(caracteristica) {
      var self = this;
      self.$set(
        caracteristica,
        "options",
        caracteristica.options.filter((opcion) => !!opcion)
      );
    },
    importarCombo(caracteristica, comboAImportar) {
      var self = this;
      Combos.get({ itemId: comboAImportar }).then((res) => {
        self.$set(
          caracteristica,
          "options",
          res.valores.map((valor) => valor.nombre)
        );
        self.$refs.popoimportar.close();
      });
    },
    _getCelda(celda) {
      const self = this,
        celdas = self.celdasTablaPersonalizada;
      if (
        !celda ||
        celda.row == undefined ||
        celda.col == undefined ||
        !self.celdasTablaPersonalizada
      ) {
        return;
      }
      return self.celdasTablaPersonalizada[celda.row][celda.col];
    },
    _addClass(classes, clazz) {
      const self = this;
      let classesArr = classes ? classes.split(" ") : [];
      classesArr.push(clazz);
      return classesArr.join(" ");
    },
    _removeClass(classes, clazz) {
      const self = this;
      let classesArr = classes ? classes.split(" ") : [];
      classesArr = classesArr.filter((clazz2) => clazz != clazz2);
      return classesArr.join(" ");
    },
    _toggleClass(classes, clazz) {
      const self = this;
      let classesArr = classes ? classes.split(" ") : [];
      if (classesArr.includes(clazz)) {
        return self._removeClass(classes, clazz);
      } else {
        return self._addClass(classes, clazz);
      }
    },
    _unescapeHTML(string) {
      var elt = document.createElement("span");
      elt.innerHTML = string;
      return elt.innerText;
    },
    _initAutocalculado() {
      const self = this;
      self._informarFormulaYResultado();
      EventBus.$on(
        "caracteristicaModificada",
        self._escucharCambioCaracteristicasDependientes
      );
    },
    _informarFormulaYResultado() {
      const self = this,
        caracteristica = self._getContext(),
        valorCompleto = self._getValorCompleto();
      self.$set(
        caracteristica,
        "valorFormula",
        self._getParteAutocalculado(valorCompleto, "FORMULA")
      );
      self.$set(
        caracteristica,
        "valorResultado",
        self._getParteAutocalculado(valorCompleto, "RESULTADO")
      );
    },
    _escucharCambioCaracteristicasDependientes(caracteristicaModificada) {
      const self = this,
        valorCompleto = self._getValorCompleto(),
        valorFormula = self._getParteAutocalculado(valorCompleto, "FORMULA");
      if (self.caracteristica.nombre.toUpperCase() == caracteristicaModificada.nombre.toUpperCase()) {
        // Si la caracteristica modificada es la propia caracteristica, actualizamos la fórmula y recalculamos
        self._modificarValor(caracteristicaModificada.valorFormula);
        self._recalcularResultado();
      } else if (!valorFormula) {
        return;
      } else if (
        valorFormula.toUpperCase().includes("[" + caracteristicaModificada.nombre.toUpperCase() + "]")
      ) {
        // Si la fórmula no incluye el valor de la caracteristica modificada, dejamos pasar el evento
        self._recalcularResultado();
      }
      return;
    },
    _recalcularResultado() {
      const self = this,
        valorCompleto = self._getValorCompleto(),
        valorFormula = self._getParteAutocalculado(valorCompleto, "FORMULA");
      if (!valorFormula) return;
      const regexpCaracteristica = /\[([^[]+)\]/g,
        matchupsCaracteristicasNecesarias =
          valorFormula.match(regexpCaracteristica),
        caracteristicasNecesarias =
          matchupsCaracteristicasNecesarias &&
          matchupsCaracteristicasNecesarias.length
            ? matchupsCaracteristicasNecesarias.map((res) =>
                res.replace(regexpCaracteristica, "$1")
              )
            : [],
        uniqueCaracteristicasNecesarias = [
          ...new Set(caracteristicasNecesarias),
        ],
        caracteristicas = self.allCaracteristicas,
        valoresNecesarios = {};
      uniqueCaracteristicasNecesarias.forEach((carac) => {
        valoresNecesarios[carac] = self._getValorCaracteristicaPorNombre(
          caracteristicas,
          carac
        );
      });
      var formulaResultado = valorFormula;
      for (const caracteristica in valoresNecesarios) {
        formulaResultado = formulaResultado.replaceAll(
          "[" + caracteristica + "]",
          valoresNecesarios[caracteristica]
        );
      }
      var resultado = "";
      try {
        resultado = eval(formulaResultado);
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,      
          maximumFractionDigits: 2,
          useGrouping: false
        });
        const resF = parseFloat(resultado);
        if (resF && !isNaN(resF)) {
          resultado = formatter.format(parseFloat(resultado));
        }
      } catch (e) {
        if (e instanceof SyntaxError) {
        } else {
          throw e;
        }
      }
      self._modificarValor(valorFormula, resultado);
      return resultado;
    },
    _modificarValor(formula, resultado) {
      const self = this,
        context = self._getContext();
      if (resultado != undefined && resultado != "") {
        self.$set(context, "valor", formula + "=" + resultado);
        self.$set(context, "valorResultado", resultado);
      } else {
        self.$set(context, "valor", formula);
        self.$set(context, "valorResultado", "");
      }
    },
    _getValorCaracteristicaPorNombre(allCaracteristicas, nombre) {
      const self = this,
        caracteristicas = self._flatCaracteristicas(allCaracteristicas),
        caracteristicaPorNombre = caracteristicas.filter(
          (caracteristica) => caracteristica.nombre.toUpperCase() == nombre.toUpperCase()
        );
      return caracteristicaPorNombre &&
        caracteristicaPorNombre.length &&
        caracteristicaPorNombre[0]["valor"]
        ? caracteristicaPorNombre[0]["valor"]
        : "";
    },
    _flatCaracteristicas(allCaracteristicas) {
      // Toma todas las características finales en un array plano
      var caracteristicas = [...allCaracteristicas],
        nuevasCaracteristicas = caracteristicas;
      while (
        nuevasCaracteristicas
          .map((car) => (car.hijas ? car.hijas.length : 0))
          .reduce((a, b) => a + b, 0) > 0
      ) {
        // Mientras en el array de nuevasCaracteristicas siga habiendo caracteristicas con hijas
        var res = nuevasCaracteristicas.map(
          (caracteristica) => caracteristica.hijas
        );
        nuevasCaracteristicas = [].concat(...res);
        caracteristicas = caracteristicas.concat(nuevasCaracteristicas);
      }
      return caracteristicas;
    },
    _getParteAutocalculado(valorCompleto, parte) {
      const self = this;
      if (!valorCompleto) return;
      var partes = valorCompleto.split("=");
      if (parte == "RESULTADO") {
        if (partes.length > 1) {
          return partes[1].trim();
        }
        return self._recalcularResultado();
      }
      return partes[0].trim();
    },
    _getFormulaAutocalculado(valorCompleto) {
      const self = this;
      return self._getParteAutocalculado(valorCompleto, "FORMULA");
    },
    _getResultadoAutocalculado(valorCompleto) {
      const self = this;
      return self._getParteAutocalculado(valorCompleto, "RESULTADO");
    },
    _getContext() {
      const self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        if (typeof context == "undefined" || context == null) context = {};
        context = context[namespaces[i]];
      }
      return context;
    },
    _getValorCompleto() {
      const self = this,
        context = self._getContext(),
        valor = context.valor;
      return valor;
    },
    _isEditable() {
      const self = this;
      return self.editable;
    },
    _isAutocalculado() {
      const self = this;
      return self.tipo == "AUTOCALCULADO";
    },
    initTablaPersonalizada() {
      const self = this;
      self.actualizarAnchoLinea();
    },
    importarDatosAuxiliares(datosAuxiliares) {
      const self = this;
      if (datosAuxiliares.celdasTablaPersonalizada != undefined) {
        self.celdasTablaPersonalizada =
          datosAuxiliares.celdasTablaPersonalizada;
      }
      if (datosAuxiliares.repetible != undefined) {
        self.repetible = datosAuxiliares.repetible;
        self.bloqueBase = Object.assign([],self.celdasTablaPersonalizada);
      }
    },
    exportarDatosAuxiliares() {
      const self = this;
      let datosAuxiliares = {};
      if (self.celdasTablaPersonalizada != undefined) {
        datosAuxiliares.celdasTablaPersonalizada =
          self.celdasTablaPersonalizada;
      }
      if (self.repetible != undefined) {
        datosAuxiliares.repetible = self.repetible;
        datosAuxiliares.bloqueBase = self.celdasTablaPersonalizada;
      }
      return datosAuxiliares;
    },

    copiarGrupo: function( grupo) {
      var self = this;
      var app = self.$f7;
      window.clipboardCaract = {... grupo};
      // self.$emit("copiado", {... grupo});
      console.log("Copiado en portapapeles", grupo);
      // app.dialog.alert("Copiado en portapapeles");
    },
    pegarGrupo: function(grupo) {
      var self = this;
      var app = self.$f7;
      if (!window.clipboardCaract) {
        app.dialog.alert("No hay datos en el portapapeles");
        return;
      }
      self.$f7.dialog
        .confirm(
          "Pegar va a reemplazar los datos del grupo destino por los copiados. ¿Desea continuar?",
          "Confirmación",
          function() {
            window.clipboardCaract.hijas.forEach(function(h,i) {
              // Si tenemos comprobación en el mismo índice que la copiada, la pegamos
              if( grupo.hijas[i]) {
                 grupo.hijas[i].valor = h.valor;
                 grupo.hijas[i].resultado = h.resultado;
              } else {
                return;
              }
            });
            self.$set(self.form,"formData",self.form.formData);
            app.preloader.show();
            self.$emit("pegado", self);
            app.preloader.hide();
            // app.dialog.alert("Datos pegados en destino");
          }
        );
    },


  },
  props: {
    name: {
      required: true,
      type: String,
    },
    form: {
      required: true,
    },
    tipo: {
      type: String,
    },
    caracteristica: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    label: String,
    placeholder: String,
    getChildPayloadCaracteristicas: Function,
    onDrop: Function,
    allCaracteristicas: Array,
    // clipboard: { required: true },
    offline: { type: Boolean },
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
    if (
      (self.tipo == "TABLA" || self.tipo == "TABLA_DINAMICA") &&
      self.caracteristica.valor
    ) {
      self.tableData = JSON.parse(self.caracteristica.valor);
    }
    if (self.tipo == "TABLA_PERSONALIZADA") {
      this.$$(this.$el).on("keypress", ".celda", function(ev) {
        if (ev.key == 'Enter') {
          ev.preventDefault();
        }
      });
    }

    if (self.tipo == "TABLA" || self.tipo == "TABLA_DINAMICA") {
      this.$$(this.$el).on("keyup", ".table-cell", function(ev) {
        if (ev.key == 'Enter') {
          ev.preventDefault();
          self.nextFocusDinTable = Utils.focusNextElement(ev, self.form.$el);
          let celdas = $el[0].querySelectorAll('span[contentEditable=true]');
          self.nextFocusDinTable = Array.prototype.indexOf.call(celdas, self.nextFocusDinTable);
          if (self.nextFocusDinTable >= celdas.length) {
            self.nextFocusDinTable = null;
          }
        }
      });
    }


    if (
      self.tipo == "TABLA_PERSONALIZADA" &&
      self.caracteristica.datosAuxiliares
    ) {
      self.importarDatosAuxiliares(
        JSON.parse(self.caracteristica.datosAuxiliares)
      );
    }
    if (self.tipo == "TRANSFORMADOR" && self.caracteristica.valor) {
      self.$refs.htmlText.innerHTML = self.caracteristica.valor;
    }
    Inspeccion.getDefineCombo({
      name: "Inspección",
      property: "inspeccion",
    }).then((opciones) => {
      var self = this;
      self.vinculables = opciones;
    });
    if (self._isAutocalculado()) {
      self._initAutocalculado();
    }
    self.montado = true;
    if (self.tipo == "TABLA_PERSONALIZADA") {
      self.initTablaPersonalizada();
    }
  }
};
</script>