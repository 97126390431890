<template>
  <div v-if="((comprobacion.hijas && comprobacion.hijas.length) || comprobacion.esGrupo) && formato && !form.readonly">
    <f7-list-item
      accordion-item
      :key="'accordion-' + name"
      ref="accordion"
      @accordion:beforeopen="$set(comprobacion, 'accordionOpened', true)"
      @accordion:beforeclose="$set(comprobacion, 'accordionOpened', false)"
      class="margin-left tpm-accordion"
    >
      <div slot="media" class="display-flex align-self-center">
        <span
          v-if="!form.readonly"
          class="column-drag-handle margin-left cursor-move"
          v-tooltip="$t('common.move')"
        >&uarr;&darr;</span>
        <input
          :value="comprobacion.codigo"
          @change="comprobacion.codigo = $event.target.value"
          placeholder="Cód."
          @click="asf"
          @touchstart="asf"
          style="width:3em; text-align:center;"
        />
      </div>
      <input
        slot="before-title"
        :value="comprobacion.nombre"
        @change="comprobacion.nombre = $event.target.value"
        placeholder="Nombre de la comprobación"
      />
      <span class="display-flex" slot="inner-end">
        Peso: <input
          v-if="formato"
          class="input-peso"
          type="number"
          :value="comprobacion.peso"
          @change="comprobacion.peso = $event.target.value"
          v-tooltip="$t('componentes.comprobacion.peso')"
        />
        <f7-button
          small
          class="display-flex align-self-center margin-right"
          icon-f7="trash"
          :icon-color="'red'"
          @click="$emit('delete', comprobacion)"
          v-tooltip="'Borrar'"
        ></f7-button>
      </span>
      
      <f7-accordion-content>
        <template v-if="comprobacion.accordionOpened">
          <Container
            group-name="comprobaciones"
            @drop="onDrop(comprobacion.hijas, $event)"
            :get-child-payload="getChildPayloadComprobaciones(comprobacion.hijas)"
            :should-accept-drop="(src, payload) => src.groupName == 'grupo-comprobaciones' || src.groupName == 'comprobaciones'"
            lock-axis="y"
            drag-handle-selector=".column-drag-handle"
          >
            <Draggable
              v-for="(hija, ih) in comprobacion.hijas"
              :key="name + '-' + ih"
            >
              <comprobacion
                :comprobacion="hija"
                :name="name + '.hijas.' + ih"
                :form="form"
                :resultadoDefecto="hija.valorInicial"
                :formato="formato"
                @delete="deleteComprobacion(comprobacion.hijas, ih, $event)"
                :getChildPayloadComprobaciones="getChildPayloadComprobaciones"
                :tabletFormat="tabletFormat"
                :pathPadre="pathPadre"
              ></comprobacion>
            </Draggable>
          </Container>
        </template>
        <template v-else>
          <div :style="'min-height: 38px; height:' + (comprobacion.hijas ? comprobacion.hijas.length * 38 : 0) + 'px;'">{{' '}}</div>
        </template>
      </f7-accordion-content>
    </f7-list-item>
  </div>
  <div 
    v-else-if="(comprobacion.hijas && comprobacion.hijas.length) || comprobacion.esGrupo"
  >
    <f7-list-item 
      accordion-item
      :key="'accordion-' + name"
      @accordion:beforeopen="$set(comprobacion, 'accordionOpened', true)"
      @accordion:beforeclose="$set(comprobacion, 'accordionOpened', false)"
      class="margin-left tpm-accordion"
    >
      <div slot="media" class="display-flex align-self-center">
        <div class="display-flex align-self-center">
          {{ comprobacion.codigo }}
        </div>
      </div>
      <div slot="before-title">{{ comprobacion.nombre }}</div>
      <div class="display-flex">
        <div v-if="formato" class="margin-right display-flex">
            <!-- Peso: {{ comprobacion.peso }} -->
          Peso: <input
            v-if="formato"
            class="input-peso"
            type="number"
            :value="comprobacion.peso"
            @change="comprobacion.peso = $event.target.value"
            v-tooltip="$t('componentes.comprobacion.peso')"
            readonly="readonly"
          />
        </div>
        <f7-button
          outline
          small
          class="display-flex align-self-center margin-right"
          :fill="allHijasNA(comprobacion)"
          @click.stop="onClickNA(comprobacion)"
          :disabled="form.readonly"
          >NA</f7-button
        >
        <f7-checkbox
          class="display-flex align-self-center margin-right"
          :checked="comprobacion.hecho"
          @change="
            comprobacion.hecho = $event.target.checked
          "
        ></f7-checkbox>
      </div>

      <div style="flex-basis: 100%; height: 0;"></div>
      <f7-accordion-content>
        <template v-if="comprobacion.accordionOpened">
          <comprobacion
            v-for="(hija, ih) in comprobacion.hijas"
            :key="name + '-' + ih"
            :comprobacion="hija"
            :name="name + '.hijas.' + ih"
            :form="form"
            :formato="formato"
            :resultadoDefecto="hija.valorInicial"
            :tabletFormat="tabletFormat"
            :pathPadre="pathPadre"
          ></comprobacion>
        </template>
        <template v-else>
          <div :style="'height:' + (comprobacion.hijas ? comprobacion.hijas.length * 38 : 0) + 'px;'">{{' '}}</div>
        </template>
      </f7-accordion-content>
    </f7-list-item>
  </div>
  <div v-else>
    <li
      :class="{
        'defecto-agregado':
          comprobacion.defectosSeleccionados &&
          comprobacion.defectosSeleccionados.length,
        'con-defecto-anterior':comprobacion.conDefectoAnterior,
      }"
    >
      <div class="item-content">
        <div class="item-inner">
          <div
            class="item-cabecera"
          >
            <span 
              v-if="formato && !form.readonly"
              class="column-drag-handle margin-left cursor-move"
              v-tooltip="$t('common.move')"
            >&uarr;&darr;</span>
            <a style="width:3em; text-align:center;" @click="popupOpenedInstrucciones = true; $refs.popupInstrucciones.open()">
              <i class="f7-icons text-color-primary comprobacion-icono">info_circle_fill</i>
            </a>
            <input
              :class="'item-codigo ' + (comprobacion.conDefectoAnterior?'con-defecto-anterior':'')"
              :value="comprobacion.codigo"
              @change="comprobacion.codigo = $event.target.value; "
              placeholder="Cód."
              style="width:4em; text-align:center;"
              :readonly="!formato || form.readonly"
            />
            <input
              class="item-titulo"
              :value="comprobacion.nombre"
              @change="comprobacion.nombre = $event.target.value"
              placeholder="Nombre."
              :readonly="!formato || form.readonly"
              v-tooltip="comprobacion.nombre"
            />
            <div v-if="formato" class="margin-right display-flex">
              {{$t('componentes.comprobacion.peso')}}: 
              <input
                class="input-peso"
                type="number"
                :value="comprobacion.peso"
                @change="comprobacion.peso = $event.target.value"
                v-tooltip="$t('componentes.comprobacion.peso')"
                :readonly="form.readonly"
              />
            </div>
            <!-- <div class="item-codigo">{{ comprobacion.codigo }}</div> -->
            <!-- <div class="item-titulo">{{ comprobacion.nombre }}</div> -->
          </div>
          <div class="item-after">
            <div class="display-flex width-100">
              <div class="comprobacion-radios align-items-center display-flex">
                <label
                  v-for="rv in [
                    { valor: 'F', color: 'green' },
                    { valor: 'D', color: 'orange' },
                    { valor: 'NA', color: 'gray' },
                  ]"
                  :key="'rvc-' + comprobacion.codigo + '-' + rv.valor"
                  class="radio label-radio"
                  :class="{ disabled: form.readonly }"
                >
                  <f7-button
                    :fill="rv.valor == 'D' ? (getValue().resultado == 'D' || getValue().resultado == 'DL' || getValue().resultado == 'DG' || getValue().resultado == 'DMG') : (getValue() && rv.valor == getValue().resultado)"
                    raised
                    :color="
                      rv.valor == 'D' &&
                      comprobacion.defectosSeleccionados &&
                      comprobacion.defectosSeleccionados.length
                        ? 'red'
                        : rv.color
                    "
                    :class="{disabled: comprobacion.bloqueado}"
                    @click="
                      setValue(rv.valor);
                      if (rv.valor != 'D') defecto = null;
                      comprobacion.modificadaInspector = true;
                    "
                    >{{ rv.valor }}</f7-button
                  >
                </label>
              </div>
              <div class="input-dropdown-wrap width-100 margin-right" v-if="!tabletFormat || getValue().resultado == 'D'">
                <select
                  :class="{ disabled: form.readonly || !formato && comprobacion.resultado != 'D' && comprobacion.resultado != 'DL' && comprobacion.resultado != 'DG' && comprobacion.resultado != 'DMG' }"
                  class="comprobacion-select"
                  @change="
                    $event.target.value
                      ? (defecto = JSON.parse(
                          JSON.stringify(
                            comprobacion.defectos[$event.target.value]
                          )
                        ))
                      : (defecto = null)
                  "
                >
                  <option value></option>
                  <option
                    v-for="(opt, io) in comprobacion.defectos"
                    :key="comprobacion.codigo + '-' + io"
                    :value="io"
                    :selected="defecto && JSON.stringify(defecto) == JSON.stringify(opt)"
                    >{{ opt.categoria }} - {{ opt.nombre }}</option
                  >
                </select>
              </div>
            </div>
            <div class="comprobacion-botones align-items-center display-flex" >
              <!-- v-if="!formato" -->
              <!-- :class="{ disabled: !defecto }" -->
              <f7-button
                v-if="comprobacionDefectuosa()"
                @click="
                  tituloPopup = 'Comentario';
                  comentarios = '';
                  popupOpenedComentarios = true;
                  $nextTick(function() {
                    $refs.popupComentario.open();
                  });
                "
                class="label-radio"
                :class="{ disabled: form.readonly || !defecto }"
                icon-material="comment"
                :icon-color="'primary'"
                v-tooltip="'Añadir observaciones'"
              ></f7-button>
              <f7-button
                v-else
                @click="
                  tituloPopup = 'Observaciones de la comprobación ' + comprobacion.codigo;
                  comentarios = comprobacion.observaciones;
                  popupOpenedComentarios = true;
                  $nextTick(function() {
                    $refs.popupComentario.open();
                  });
                "
                class="label-radio"
                :class="{ disabled: form.readonly }"
                icon-material="comment"
                :icon-color="'primary'"
                v-tooltip="'Añadir observaciones'"
              ></f7-button>
              <!-- v-if="!formato" -->
              <!-- :class="{ disabled: !defecto }" -->
              <f7-button
                v-if="comprobacionDefectuosa()"
                class="label-radio"
                :icon-material="tabletFormat?'perm_camera_mic':'attach_file_add'"
                v-tooltip="tabletFormat?'Multimedia':'Documentación'"
                :icon-color="'primary'"
                @click="$refs.popoverMultimedia.open($event.target)"
                :class="{ disabled: form.readonly || !defecto}"
                ref="targetPopoverMultimedia"
              ></f7-button>
              <f7-button
                v-else
                class="label-radio"
                :icon-material="tabletFormat?'perm_camera_mic':'attach_file_add'"
                v-tooltip="tabletFormat?'Multimedia':'Documentación'"
                :icon-color="'primary'"
                @click="$refs.popoverMultimedia.open($event.target)"
                :class="{ disabled: form.readonly}"
                ref="targetPopoverMultimedia"
              ></f7-button>
              <f7-popover ref="popoverMultimedia" class="popover-menu">
                <f7-list>
                  <f7-list-item
                    v-if="tabletFormat"
                    popover-close
                    title="Foto"
                    @click="Utils.capturarFoto(form, comprobacionDefectuosa() ? 'tmpdocumentos' : name + '.documentos', Utils.getInspeccionPath(pathPadre + (comprobacionDefectuosa() ? `/Defectos/${defecto.codigo}` : `/Comprobaciones/${comprobacion.codigo}`), form.formData), $set)"
                    link="#"
                  >
                    <f7-icon
                      slot="media"
                      material="add_a_photo"
                      color="primary"
                    ></f7-icon>
                  </f7-list-item>
                  <f7-list-item
                    v-if="tabletFormat"
                    popover-close
                    title="Vídeo"
                    @click="Utils.grabarVideo(form, comprobacionDefectuosa() ? 'tmpdocumentos' : name + '.documentos', Utils.getInspeccionPath(pathPadre + (comprobacionDefectuosa() ? `/Defectos/${defecto.codigo}` : `/Comprobaciones/${comprobacion.codigo}`), form.formData), $set)"
                    link="#"
                  >
                    <f7-icon
                      slot="media"
                      material="video_call"
                      color="primary"
                    ></f7-icon>
                  </f7-list-item>
                  <f7-list-item
                    v-if="tabletFormat"
                    popover-close
                    title="Audio"
                    @click="Utils.grabarAudio(form, comprobacionDefectuosa() ? 'tmpdocumentos' : name + '.documentos', Utils.getInspeccionPath(pathPadre + (comprobacionDefectuosa() ? `/Defectos/${defecto.codigo}` : `/Comprobaciones/${comprobacion.codigo}`), form.formData), $set)"
                    link="#"
                  >
                    <f7-icon
                      slot="media"
                      material="mic"
                      color="primary"
                    ></f7-icon>
                  </f7-list-item>
                  <f7-list-item
                    popover-close
                    title="Documento "
                    @click="anadirDocumento"
                    link="#"
                  >
                    <f7-icon
                      slot="media"
                      material="attach_file"
                      color="primary"
                    ></f7-icon>
                  </f7-list-item>
                </f7-list>
              </f7-popover>
              <f7-button
                v-if="!formato && (!tabletFormat || getValue().resultado == 'D' || getValue().resultado == 'DL' || getValue().resultado == 'DG' || getValue().resultado == 'DMG')"
                @click="anadirDefecto()"
                class="label-radio"
                icon-f7="plus"
                :icon-color="'primary'"
                :class="{ disabled: !defecto }"
                v-tooltip="'Añadir defecto'"
              ></f7-button>
              <f7-button
                v-if="formato && !form.readonly"
                class="label-radio"
                icon-f7="trash"
                :icon-color="'red'"
                @click="$emit('delete', comprobacion)"
                v-tooltip="'Borrar'"
              ></f7-button>
            </div>
          </div>
        </div>
      </div>

      <f7-popup
        @popup:closed="popupOpenedInstrucciones = false"
        ref="popupInstrucciones"
      >
        <f7-page>
          <f7-navbar title="Instrucciones">
            <f7-nav-right>
              <f7-link
                @click="$refs.popupInstrucciones.close()"
                icon-f7="multiply"
              ></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <r-rich-text-input
              :disabled="!formato || form.readonly"
              class="col-100"
              floating-label
              :label="comprobacion.nombre"
              :placeholder="$t('componentes.comprobacion.instrucciones')"
              :form="form"
              :name="name+'.instrucciones'"
              :showVideoButton="true"
            ></r-rich-text-input>
          </f7-block>
        </f7-page>
      </f7-popup>
      <f7-popup
        v-if="popupOpenedComentarios"
        @popup:closed="popupOpenedComentarios = false"
        ref="popupComentario"
      >
        <f7-page>
          <f7-navbar :title="tituloPopup">
            <f7-nav-right>
              <f7-link
                @click="$refs.popupComentario.close()"
                icon-f7="checkmark"
              ></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <div class="list no-hairlines no-margin full-height">
            <ul class="full-height">
              <li class="item-content item-input full-height">
                <div class="item-inner full-height">
                  <div class="item-input-wrap full-height">
                    <textarea
                      placeholder="Escribe aquí algún comentario..."
                      class="full-height textoAzul"
                      :value="comentarios"
                      @change="setComment($event.target.value)"
                    ></textarea>
                    <f7-button
                      outline
                      class="display-flex align-self-center margin-right"
                      fill
                      @click="$refs.popupComentario.close()"
                    >Guardar</f7-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </f7-page>
      </f7-popup>
    </li>
  </div>
</template>
<style>
.tpm-accordion.accordion-item .item-link{
  flex: 50%;
}
</style>
<style scoped>
/* COMPROBACIONES */

.tpm-accordion.accordion-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tpm-accordion.accordion-item .accordion-item-content {
  width: 100%;
}

.item-codigo, .item-titulo {
  border: none;
  background: transparent;
  width: 100%;
}

.comprobacion-select {
  margin: 0 10px;
  width: 200px;
}

.comprobacion-botones > *,
.comprobacion-radios .button {
  min-width: 2.2rem;
  width: 2.2rem;
  margin: 0 2px;
  padding: 0;
}

.radio input[type="radio"]:not(:checked) ~ .button {
  background: white;
  color: gray;
}

.defecto-agregado .radio input[type="radio"] ~ .button.color-orange {
  background: #f44336;
}

.defecto-agregado label.radio {
  pointer-events: none !important;
}

.accordion-item .accordion-item-content .item-cabecera {
  display: flex;
  width: 100%;
}
.accordion-item .accordion-item-content .item-codigo {
  font-weight: bold;
  flex: 0 0 3em;
}
.comprobacion-botones {
  width: auto;
}

.accordion-item {
  background-color: #f3f3f3;
}
.accordion-item-content {
  background-color: white;
}
.block-title > input {
  background: transparent;
  border: none;
  font-weight: bold;
  width: 100%;
}
.accordion-item > .item-link input {
  border: none;
  background: transparent;
  width: auto;
  width: 100%;
}
.accordion-item > .nombre-precarga,
.accordion-item > .nombre-plantilla {
  border: none;
  background: transparent;
  width: auto;
  width: 95%;
}
.accordion-item .file-container{
  display:inline-block;
  width: 150px;
}
.accordion-item > .item-link input.input-peso, div.margin-right input.input-peso {
  width: 35px;
  border: 1px solid black;
  background-color: white;
  margin-left: 5px;
  padding-left: 2px;
}
.last-row-item-in-accordion {
  margin-bottom: 10em;
}

.textoAzul {
  color: blue;
  text-transform: uppercase;
}
.con-defecto-anterior {
  color: red;
}
li.con-defecto-anterior {
  border: 1px dashed red;
  background-color: #ffefef;
}
@media (max-width: 767px) {
  .accordion-item .accordion-item-content .item-cabecera {
    width: 100%;
  }
  .accordion-item .accordion-item-content .item-titulo {
    width: 100%;
  }

  .accordion-item .accordion-item-content .item-content {
    padding: 0;
  }
  .accordion-item .accordion-item-content .item-inner {
    flex-direction: column;
    align-items: start;
    padding: 5px;
  }
  .accordion-item .accordion-item-content .item-after {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .comprobacion-select {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .accordion-item .accordion-item-content .item-after {
    flex-direction: column;
    align-items: start;
  }
  .comprobacion-botones {
    margin: 0px 5px 0px auto;
  }
}
</style>
<script>
import moment from 'moment';
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import DocumentoOffline from "./../../js/db/DocumentoOffline.js";
import rRichTextInput from "./../../components/rRichTextInput.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Utils from "./../../js/Utils";
import { EventBus } from "../../js/EventBus";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  console.log(arr, dragResult);
  //const result = [...arr];
  if (!arr) arr = [];
  var result = arr;

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = Object.assign({},  result.splice(removedIndex, 1)[0]);
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, Object.assign({}, itemToAdd));
  }

  //return result;
};

export default {
  name: "comprobacion",
  components: { rForm, rInput, rFiles, rRichTextInput, Container, Draggable },
  data() {
    return {
      self: this,
      popupOpenedComentarios: false,
      popupOpenedInstrucciones: false,
      // Para el nuevo defecto
      comentarios: "",
      defecto: null,
      // documentos: [],
      DocumentoOffline,
      tituloPopup: "Comentario",
      comentarios: "",
      Utils
    };
  },
  props: {
    form: {},
    comprobacion: { type: Object },
    name: { type: String },
    referencia: { type: String },
    estandar: { type: String },
    subEstandar: { type: String },
    resultadosValidos: { type: Array },
    resultadoDefecto: { type: String },
    formato: { type: Boolean, default: false },
    getChildPayloadComprobaciones: Function,
    tabletFormat: { type: Boolean, default: false },
    pathPadre: { type: String, default: "Documentos" }
  },
  methods: {
    onClickNA(comprobacion) {
      const self = this;
      if (comprobacion && comprobacion.hijas && comprobacion.hijas.length) {
        comprobacion.hijas.forEach((hija, idx) => {
          if (hija.resultado != 'D' && hija.resultado != 'DL' && hija.resultado != 'DG' && hija.resultado != 'DMG') {
            self.$set(comprobacion.hijas[idx], "resultado", "NA");
            self.$set(comprobacion.hijas[idx], "modificadaInspector", true);
          }
          self.onClickNA(hija);
        });
      }
    },
    allHijasNA(comprobacion) {
      const self = this;
      var resultado = true;
      if (comprobacion && comprobacion.hijas && comprobacion.hijas.length) {
        for (const hija of comprobacion.hijas) {
          var res = hija.resultado;
          if (typeof res == "undefined") {
            res = hija.valorInicial;
          }
          if (res != "NA") {
            resultado = false;
            return resultado;
          } else {
            resultado = self.allHijasNA(hija);
            if (!resultado) {
              return resultado;
            }
          }
        }
      }
      return resultado;
    },
    onDrop(arr, dropResult) {
      applyDrag(arr, dropResult);
    },
    asf($event) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      return false;
    },
    deleteComprobacion(comprobaciones, ic, comprobacion) {
      var self = this;
      applyDrag(comprobaciones, {removedIndex: ic, payload: null, addedIndex: null});
    },
    anadirDocumento() {
      var self = this;
      var app = self.$f7;
      var x = document.createElement("INPUT");
      x.setAttribute("type", "file");
      x.onchange = function(e) {
        Array.from(e.target.files).forEach((file) => {
          if (self.tabletFormat) {
            // const numFileName = (!value || !value.length ? 0 : Math.max(...value.map(d => d.nombre.indexOf("[") < 0 ? 0 : parseInt(d.nombre.split(']')[0].split('[').slice(-1).join() || "0", 10) || 0))) + 1;
            const timestamp = moment().format("YYYYMMDDHHmmss");
            const offlinePath = Utils.getInspeccionPath(self.pathPadre + (self.comprobacionDefectuosa() ? `/Defectos/${self.defecto.codigo}` : `/Comprobaciones/${self.comprobacion.codigo}`), self.form.formData);
            DocumentoOffline.saveBlob(file, 'Documents/', `${offlinePath}/${timestamp}_${file.name}`)
              .then(({ filePath, localURL }) => {
                var fileLoaded = {
                  nombre: file.name.split(".").slice(0, -1).join("."),
                  extension: /(?:\.([^.]+))?$/.exec(file.name)[1], //file.type.split("/")[1],
                  _localURL: localURL,
                  offlinePath: filePath
                };
                if (self.comprobacionDefectuosa()) {
                  if (!self.form.formData.tmpdocumentos) { self.form.formData.tmpdocumentos = []; }
                  self.form.formData.tmpdocumentos.push(fileLoaded);
                } else {
                  if (!self.comprobacion.documentos) { self.comprobacion.documentos = []; }
                  self.comprobacion.documentos.push(fileLoaded);
                }
                app.preloader.hide();

              }).catch(e => {
                app.dialog.alert("Error guardando documento " + e);
                app.preloader.hide();
              })
          } else {
            var reader = new FileReader();
            reader.onload = function (e2) {
              if (self.comprobacionDefectuosa()) {
                if (!self.form.formData.tmpdocumentos) { self.form.formData.tmpdocumentos = []; }
                self.form.formData.tmpdocumentos.push({
                  nombre: file.name
                    .split(".")
                    .slice(0, -1)
                    .join("."),
                  extension: /(?:\.([^.]+))?$/.exec(file.name)[1],
                  _base64: e2.target.result,
                });
              } else {
                if (!self.comprobacion.documentos) { self.comprobacion.documentos = []; }
                self.comprobacion.documentos.push({
                  nombre: file.name
                    .split(".")
                    .slice(0, -1)
                    .join("."),
                  extension: /(?:\.([^.]+))?$/.exec(file.name)[1],
                  _base64: e2.target.result,
                });
              }
              app.preloader.hide();
            };
            reader.readAsDataURL(file);
          }
          app.preloader.show();
        });
      };
      x.click();
    },
    anadirDefecto() {
      var self = this;
      // self.defecto.observaciones = self.comentarios;
      // self.defecto.documentos = self.documentos;
      // var defSeleccionados = self.comprobacion.defectosSeleccionados || [];
      // defSeleccionados.push(self.defecto);
      // self.$set(self.comprobacion, "defectosSeleccionados", defSeleccionados);
      self.comprobacion.defectosSeleccionados.push(Object.assign({}, self.defecto, { observaciones: self.comentarios, documentos: self.form.formData.tmpdocumentos || []}));
      self.defecto = null;
      self.comentarios = "";
      self.form.formData.tmpdocumentos = [];
    },
    comprobacionDefectuosa() {
      var self = this;
      let valor = self.getValue();
      let resultado = valor?valor.resultado:valor;
      console.log(resultado);
      return resultado == "D" || resultado == "DL" || resultado == "DG" || resultado == "DMG";
    },
    getValue: function() {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined") {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      //context = context["resultado"];
      return context;
    },
    setValue: function(val) {
      var self = this;
      var namespaces = self.name.split(".");
      var context = self.form.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (i == namespaces.length - 1) {
          //self.$set(context, namespaces[i], val);
          if (typeof context[namespaces[i]] == "undefined" || context[namespaces[i]] == null) {
            let n = namespaces[i + 1];
            if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
              //Número entero positivo
              self.$set(context, namespaces[i], []);
            } else {
              self.$set(context, namespaces[i], {});
            }
          }
          self.$set(self.comprobacion, "resultado", val);
          self.$set(context[namespaces[i]], "resultado", val);
          self.$emit("changeChild", self);
        } else if (typeof context[namespaces[i]] == "undefined") {
          let n = namespaces[i + 1];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            self.$set(context, namespaces[i], []);
          } else {
            self.$set(context, namespaces[i], {});
          }
        }
        context = context[namespaces[i]];
      }
    },
    setComment: function(val) {
      var self = this;
      // si la comprobación es defectuosa, se añadirá el comentario al defecto seleccionado, pero si no lo es, se añadirá al campo obserbaciones de la comprobación
      if (self.comprobacionDefectuosa()) {
        self.comentarios = val.toUpperCase();
      } else {
        self.$set(self.comprobacion, "observaciones", val.toUpperCase());
      }
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    if (self.comprobacion.valorInicial && (!self.getValue() || !self.getValue().resultado)) {
      self.setValue(self.comprobacion.valorInicial);
    }
    if (!self.comprobacion.defectosSeleccionados) {
      self.$set(self.comprobacion, "defectosSeleccionados", []);
    }
    EventBus.$on("switchAccordion", (event) => {
      console.log(self.nombre);
      self.$nextTick(() => {
        if(event) {
          try {
            app.preloader.show();
            self.$f7.accordion.open(self.$el.querySelector('.tpm-accordion'));
          } catch (e) {
            console.log(e);
          } finally {
            app.preloader.hide();
          };
        } else {
          try {
            app.preloader.show();
            self.$f7.accordion.close(self.$el.querySelector('.tpm-accordion'));
          } catch (e) {
            console.log(e);
          } finally {
            app.preloader.hide();
          };
        }
      });
    });
  },
};
</script>
