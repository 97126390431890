<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('clientes.clientes')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Cliente"
        :tableCode="'CLIENTE'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :controlsWidth="70"
      >
        <template v-slot:tableLinks>
          <r-form
            cass="form-inline"
            ref="rformImport"
            v-slot="{rform}"
            :readonly="false"
            :dbAdapter="null"
            :documentoAdapter="Documento"
            :beforeSave="beforeSaveImport"
            @save="onImport"
            @error="onImportError"
          >
            <r-files
              path="/importacion-clientes/"
              :conservarNombre="true"
              :sobrescribir="true"
              :form="rform"
              name="documento"
              :maxFiles='1'
              label="Importar Clientes"
              @loadFile="loadFile"
              :preview="false"
            ></r-files>
            <div class="nuevo_cliente"><a class="link" href="/clientes/new/">Nuevo cliente</a></div>
          </r-form>
        </template>
        <!-- <template v-slot:extendedActions>
          <f7-link icon-f7="exclamationmark_triangle" color="red" @click="popupTareaEditable = true; popupTareasOpened = true" v-tooltip="'Buscar Duplicados'"></f7-link>
        </template> -->
        <template v-slot:optionsColumn="{item}">
          <f7-link icon-f7="eye" color="green" :href="`/clientes/view/${item.id}/`" v-tooltip="'Ver Cliente'"></f7-link>
          <f7-link icon-f7="square_pencil" color="blue" :href="`/clientes/edit/${item.id}/`" v-tooltip="'Editar Cliente'"></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>

    <!-- <f7-popup ref="popupTarea" swipe-to-close :opened="true" v-if="popupTareasOpened" @popup:closed="popupTareasOpened = false; tareaId = null">
      <clientes-duplicados :id="tareaId" :editable="popupTareaEditable" @close="$refs.popupTarea.close();"  
        :defaultData="{numeroPedido: $refs.rform.formData.numero, estado: 'REALIZADA', fechaFecha: new Date(), fechaHora: new Date()}" :esPopup="true"
      ></clientes-duplicados>
    </f7-popup> -->
  </f7-page>
</template>
<style scoped>
  .nuevo_cliente {
    text-align: center;
    /* background-color: greenyellow; */
    border-radius: 5px;
    margin: 1px 16px;
    padding: 4px;
  }
  .nuevo_cliente:hover {
    background-color: greenyellow;
    border-radius: 5px;
    margin: 1px 16px;
    padding: 4px;
  }
  .buscar_duplicados {
    text-align: center;
    /* background-color: greenyellow; */
    border-radius: 5px;
    margin: 1px 16px;
    padding: 4px;
  }
  .buscar_duplicados:hover {
    background-color: red;
    border-radius: 5px;
    margin: 1px 16px;
    padding: 4px;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Utils from "./../../js/Utils.js";
import Cliente from "../../js/db/Cliente.js";
import rForm from "../../components/rForm.vue";
import rFiles from "../../components/rFiles.vue";
import Documento from "../../js/db/Documento.js";
export default {
  components: {
    rDynamicList,
    rForm,
    rFiles,
  },
  data() {
    return {
      Cliente,
      Utils,
      Documento,
      popupTareasOpened: false,
    };
  },
  computed: {},
  methods: {
    onImport: function (res) {
      var self = this;
      var app = self.$f7;
      // Si res.documento es un array, nos quedamos con el primer elemento
      let documento = Array.isArray(res.documento) ? res.documento[0] : res.documento;
      self.Cliente.importar(documento).then((res) => {
        self.$set(self.$refs.rformImport.formData,'documento',[]);
        self.$refs.rlist.submitFilterForm();
        app.preloader.hide();
      }).finall;
    },
    onImportError: function (e) {
      var self = this;
      var app = self.$f7;
      app.preloader.hide(); 
      // TODO mensaje error;
    },
    beforeSaveImport: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      var o = Object.assign({}, data);
      o.documento = Array.isArray(o.documento) ? o.documento[0] : o.documento;
      app.preloader.hide();
      resolve(o);
    },
    loadFile: function () {
      var self = this;
      var app = self.$f7;
      self.$refs.rformImport.saveItem();
      app.preloader.show();
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>